<template>
  <div id="app">
    <Page>
      <template v-if="!embedded" #navigation>
        <NavigationBar
          :logo-link="
            user && (user.role === 'admin' || user.role === 'agent') ? '/' : ''
          "
        >
          <template #logo>
            <img class="logo" src="/img/Belimo_Logo.png" />
          </template>
          <Button v-if="environment !== 'production'">{{ environment }}</Button>
          <NavigationItem
            v-if="user && (user.role === 'admin' || user.role === 'agent')"
            to="/"
          >
            {{ $t("navigation.events") }}
          </NavigationItem>
          <template #right>
            <UserDropdownMenu
              v-if="user && (user.role === 'admin' || user.role === 'agent')"
            />
            <LocaleSwitcher />
          </template>
        </NavigationBar>
      </template>
      <template v-if="embedded">
        <LocaleSwitcher v-show="false" />
      </template>
      <Grid v-if="!embedded" layout="fluid" class="hero-grid">
        <GridColumn :columns="12">
          <div class="hero-image" />
        </GridColumn>
      </Grid>
      <router-view />
    </Page>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "@gospore/gospore-web-ui-library-theme";
import "@/theme.less";
import Page, { Grid, GridColumn } from "@gospore/gospore-web-ui-library-layout";
import NavigationBar, {
  NavigationItem,
} from "@gospore/gospore-web-ui-library-navigation";
import Button from "@gospore/gospore-web-ui-library-button";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import UserDropdownMenu from "@/components/UserDropdownMenu";

export default {
  name: "App",
  components: {
    Page,
    Grid,
    GridColumn,
    NavigationBar,
    NavigationItem,
    Button,
    LocaleSwitcher,
    UserDropdownMenu,
  },
  computed: {
    embedded() {
      return this.$route.query.embedded
        ? this.$route.query.embedded === "true"
          ? true
          : false
        : false;
    },
    environment() {
      return process ? process.env.NODE_ENV : "Not available";
    },
    ...mapState(["user"]),
  },
};
</script>

<style lang="less">
.navigation-bar {
  background: @go-color-n0 !important;
  border-bottom: @borderWidth solid @go-color-n30;
}
.logo {
  height: 36px;
}
.navigation-bar a {
  color: @go-color-n500 !important;
}
.hero-grid {
  padding-bottom: @size0 !important;
}
.hero-image {
  background-image: url("/img/Belimo_Roadshow_Hero.jpg");
  height: 200px;
  background-size: cover;
  background-position: center center;
  border-radius: @borderRadius;
}
// h1,
// h2,
// h3,
// h4,
// h5 {
//   color: @text-color !important;
// }
h1 {
  margin-bottom: @size24 !important;
}
.button-appearance-default {
  background-color: @go-color-n30 !important;

  &:hover {
    background-color: @go-color-n40 !important;
  }
  &:active {
    background-color: fade(@go-color-b75, 60%) !important;
  }
}
.button-appearance-primary {
  background-color: @color-secondary-shaded-blue !important;

  &:hover {
    background-color: darken(@color-secondary-shaded-blue, 10%) !important;
  }
  &:active {
    background-color: lighten(@color-secondary-shaded-blue, 10%) !important;
  }
  &:disabled {
    background-color: @go-color-n20 !important;
  }
}
.button-appearance-selected {
  background-color: darken(@color-secondary-shaded-blue, 10%) !important;

  &:hover {
    background-color: darken(@color-secondary-shaded-blue, 15%) !important;
  }
  &:active {
    background-color: lighten(@color-secondary-shaded-blue, 15%) !important;
  }
}
.select-trigger.focused {
  border: @borderWidth solid @color-primary !important;
}
.textfield-input:focus-within {
  border: @borderWidth solid @color-primary !important;
}
.radio-input.radio-checked {
  color: @color-primary !important;

  & circle:first-of-type {
    stroke: @color-primary !important;
  }
}
.radio:active .radio-input {
  color: lighten(@color-primary, 20%) !important;

  & circle:first-of-type {
    stroke: lighten(@color-primary, 20%) !important;
  }
}
.navigation-item-link:hover {
  background-color: @go-color-n30 !important;

  &:active {
    background-color: fade(@go-color-b75, 60%) !important;
    color: @go-color-b400 !important;
  }
}
.router-link-exact-active:after {
  background-color: @color-secondary-shaded-blue !important;
}
</style>
