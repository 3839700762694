<template>
  <div class="locale-switcher">
    <DropdownMenu v-model="open" :text="selectedLocale">
      <DropdownItemGroup>
        <DropdownItemRadio
          v-for="(localeKey, localeName) in locales"
          :key="localeKey"
          v-model="selectedLocale"
          :value="localeName"
        />
      </DropdownItemGroup>
    </DropdownMenu>
  </div>
</template>

<script>
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItemRadio,
} from "@gospore/gospore-web-ui-library-dropdown-menu";

export default {
  name: "LocaleSwitcher",
  components: {
    DropdownMenu,
    DropdownItemGroup,
    DropdownItemRadio,
  },
  data() {
    return {
      open: false,
      locales: {
        Deutsch: "de",
        English: "en",
        español: "es",
        français: "fr",
        italiano: "it",
        Nederlands: "nl",
        polski: "pl",
        Türkçe: "tr",
        magyar: "hu",
        norsk: "no",
        suomi: "fi",
        svenska: "sv",
      },
      selectedLocale: "English",
    };
  },
  computed: {
    languageQuery() {
      return this.$route.query.lang;
    },
  },
  watch: {
    selectedLocale() {
      this.$i18n.locale = this.locales[this.selectedLocale];
    },
    "$route.query.lang": {
      handler: function () {
        if (this.languageQuery) {
          this.selectedLocale =
            Object.keys(this.locales).find(
              (key) => this.locales[key] === this.languageQuery
            ) ?? "English";
        } else {
          this.selectedLocale =
            Object.keys(this.locales).find(
              (key) => this.locales[key] === this.$i18n.locale
            ) ?? "English";
        }
        this.$i18n.locale = this.locales[this.selectedLocale];
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
