<template>
  <Form
    novalidate="true"
    class="create-reservation-view"
    @submit="validateForm()"
  >
    <FormHeader :title="$t('createReservationView.titleContactDetails')" />
    <FormSection>
      <b
        v-if="reservationToEdit !== null"
        v-t="'createReservationView.descriptionEdit'"
      />
    </FormSection>
    <ReservationParticipantFormSection
      v-model="participant"
      :edit-mode="reservationToEdit !== null"
    />
    <h4>{{ $t("createReservationView.titleAccompanyingPersons") }}</h4>
    <ReservationParticipantFormSection
      v-for="(accompanyingParticipant, index) in accompanyingParticipants"
      :key="index"
      v-model="accompanyingParticipants[index]"
      :index="index"
      :edit-mode="accompanyingParticipant._id !== undefined"
      @remove-participant="removeAccompanyingParticipant"
    />
    <FormSection>
      <hr v-if="accompanyingParticipants.length" />
      <p
        v-if="
          timeSlot.availableParticipants <= currentlyAccompanyingParticipants
        "
        class="warning warning-time-slot-full"
      >
        {{
          $tc(
            "createReservationView.warningTimeSlotFull",
            timeSlot.availableParticipants,
            { availableParticipants: timeSlot.availableParticipants }
          )
        }}
      </p>
      <Button
        :disabled="
          timeSlot.availableParticipants <= currentlyAccompanyingParticipants
        "
        @click="addAccompanyingParticipant()"
      >
        {{ $t("createReservationView.buttonAddAccompanyingParticipant") }}
      </Button>
    </FormSection>
    <FormSection
      v-if="
        validationErrors.length ||
        createReservationMutationError ||
        updateReservationMutationError ||
        cancelReservationMutationError ||
        timeSlotByIDQueryError
      "
    >
      <div v-if="validationErrors.length">
        <b v-t="'shared.validationErrorDescription'" />
        <ul>
          <li
            v-for="validationError in validationErrors"
            :key="validationError"
          >
            <p>{{ validationError }}</p>
          </li>
        </ul>
      </div>
      <p v-if="createReservationMutationError" class="error">
        {{ createReservationMutationError.message }}
      </p>
      <p v-if="updateReservationMutationError" class="error">
        {{ updateReservationMutationError.message }}
      </p>
      <p v-if="cancelReservationMutationError" class="error">
        {{ cancelReservationMutationError.message }}
      </p>
      <p v-if="timeSlotByIDQueryError" class="error">
        {{ timeSlotByIDQueryError.message }}
      </p>
    </FormSection>
    <FormFooter>
      <ButtonGroup>
        <Button
          v-if="reservationToEdit"
          appearance="danger"
          :disabled="reservationInProgress || reservationCancellationInProgress"
          @click="cancelReservationModalVisible = true"
        >
          {{ $t("shared.buttonBookCancel") }}
        </Button>
        <Button
          :disabled="reservationInProgress"
          type="submit"
          appearance="primary"
          >{{
            reservationToEdit
              ? $t("shared.buttonSave")
              : $t("shared.buttonBook")
          }}</Button
        >
        <Button
          :disabled="reservationInProgress"
          appearance="subtle"
          @click="cancel()"
          >{{ $t("shared.buttonCancel") }}</Button
        >
      </ButtonGroup>
    </FormFooter>
    <Modal
      v-model="cancelReservationModalVisible"
      :close-on-overlay-click="!reservationCancellationInProgress"
    >
      <template #header>
        <ModalHeader
          :title="$t('reservationRow.cancelReservationModalTitle')"
        />
      </template>
      <p>{{ $t("reservationRow.cancelReservationModalDescription") }}</p>
      <p v-if="cancelReservationMutationError" class="error">
        {{ cancelReservationMutationError.message }}
      </p>
      <template #footer>
        <ModalFooter>
          <ButtonGroup>
            <Button
              :disabled="reservationCancellationInProgress"
              appearance="danger"
              @click="cancelReservation()"
            >
              {{ $t("shared.dropdownItemDelete") }}
            </Button>
            <Button
              :disabled="reservationCancellationInProgress"
              appearance="subtle"
              @click="cancelReservationModalVisible = false"
            >
              {{ $t("shared.buttonCancel") }}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </template>
    </Modal>
  </Form>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
const cloneDeep = require("lodash.clonedeep");
import { ICalendar } from "datebook";
// const PhoneNumber = require("awesome-phonenumber");
import Form, {
  FormHeader,
  FormSection,
  FormFooter,
} from "@gospore/gospore-web-ui-library-form";
import Button, { ButtonGroup } from "@gospore/gospore-web-ui-library-button";
import Modal, {
  ModalHeader,
  ModalFooter,
} from "@gospore/gospore-web-ui-library-modal";
import ReservationParticipantFormSection from "@/components/ReservationParticipantFormSection.vue";

import CreateReservationMutation from "@/graphql/CreateReservationMutation.gql";
import ReservationsQuery from "@/graphql/ReservationsQuery.gql";
import TimeSlotByIDQuery from "@/graphql/TimeSlotByIDQuery.gql";
import UpdateReservationMutation from "@/graphql/UpdateReservationMutation.gql";
import CreateParticipantsMutation from "@/graphql/CreateParticipantsMutation.gql";
import CancelReservationMutation from "@/graphql/CancelReservationMutation.gql";
import TimeSlotsQuery from "@/graphql/TimeSlotsQuery.gql";

export default {
  name: "CreateReservationView",
  components: {
    Form,
    FormHeader,
    FormSection,
    FormFooter,
    Button,
    ButtonGroup,
    Modal,
    ModalHeader,
    ModalFooter,
    ReservationParticipantFormSection,
  },
  data() {
    return {
      timeSlot: Object,
      participant: this.$store.state.reservationToEdit
        ? this.$store.state.reservationToEdit.participant
        : {
            title: "_0",
            firstname: null,
            lastname: null,
            emailAddress: null,
            company: null,
            phoneNumber: null,
            addressPostalCode: null,
            addressCity: null,
            addressCountry: null,
          },
      accompanyingParticipants: this.$store.state.reservationToEdit
        ? cloneDeep(
            this.$store.state.reservationToEdit.accompanyingParticipants
          )
        : [],
      icalendar: null,
      createReservationMutationError: null,
      updateReservationMutationError: null,
      cancelReservationMutationError: null,
      timeSlotByIDQueryError: null,
      validationErrors: [],
      reservationInProgress: false,
      reservationCancellationInProgress: false,
      cancelReservationModalVisible: false,
    };
  },
  computed: {
    currentlyAccompanyingParticipants() {
      this.$store.commit(
        "setReservationCurrentlyAccompanyingParticipants",
        this.accompanyingParticipants.length + 1
      );

      if (this.reservationToEdit) {
        if (this.selectedTimeSlotID === this.reservationToEdit.timeSlot._id) {
          return (
            this.accompanyingParticipants.length -
            this.reservationToEdit.accompanyingParticipants.length
          );
        }
      }
      return this.accompanyingParticipants.length + 1;
    },
    timeZone() {
      let resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
      let timeZone = {
        locale: resolvedOptions.locale,
        timeZone: resolvedOptions.timeZone,
        offset: new Date().getTimezoneOffset(),
      };
      return JSON.stringify(timeZone);
    },
    ...mapState(["event", "reservationToEdit", "selectedTimeSlotID"]),
    ...mapGetters(["localizedText"]),
  },
  watch: {
    selectedTimeSlotID() {
      this.createReservationMutationError = null;
      this.timeSlotByIDQueryError = null;
      this.timeSlotFullWarning = null;
    },
    reservationToEdit() {
      if (this.reservationToEdit) {
        this.participant = this.reservationToEdit.participant;
        this.accompanyingParticipants = cloneDeep(
          this.reservationToEdit.accompanyingParticipants
        );
      }
    },
  },
  apollo: {
    timeSlot: {
      query: TimeSlotByIDQuery,
      variables() {
        return {
          query: { _id: this.selectedTimeSlotID },
        };
      },
      fetchPolicy: "cache-and-network",
      error(error) {
        this.timeSlotByIDQueryError = error;
      },
    },
  },
  methods: {
    addAccompanyingParticipant() {
      this.accompanyingParticipants.push({
        title: "_0",
        firstname: null,
        lastname: null,
        emailAddress: null,
        company: null,
        phoneNumber: null,
        addressPostalCode: null,
        addressCity: null,
        addressCountry: null,
      });
    },
    removeAccompanyingParticipant(index) {
      this.accompanyingParticipants.splice(index, 1);
    },
    validateForm() {
      this.validationErrors = [];

      this.validateParticipant(this.participant);
      for (const accompanyingParticipant of this.accompanyingParticipants) {
        this.validateParticipant(accompanyingParticipant, true);
      }

      if (!this.validationErrors.length) {
        if (this.reservationToEdit) {
          this.updateReservation();
        } else {
          this.createReservation();
        }
      }
    },
    validateParticipant(participant, isAccompanyingParticipant = false) {
      if (
        !participant.firstname ||
        !participant.lastname ||
        !participant.emailAddress ||
        !participant.company ||
        !participant.phoneNumber ||
        participant.firstname === "" ||
        participant.lastname === "" ||
        participant.emailAddress === "" ||
        participant.company === "" ||
        participant.phoneNumber === ""
      ) {
        const missingNeccessaryFieldError = !isAccompanyingParticipant
          ? this.$t("shared.validationErrorMissingNeccessaryField")
          : this.$tc(
              "createReservationView.validationErrorAccompanyingParticipantMissingNeccessaryField",
              this.accompanyingParticipants.length
            );
        if (!this.validationErrors.includes(missingNeccessaryFieldError)) {
          this.validationErrors.push(missingNeccessaryFieldError);
        }
      } else {
        if (!this.validEmail(participant.emailAddress)) {
          const invalidEmailAddressError = !isAccompanyingParticipant
            ? this.$t(
                "createReservationView.validationErrorInvalidEmailAddress"
              )
            : this.$t(
                "createReservationView.validationErrorAccompanyingParticipantInvalidEmailAddress",
                {
                  firstname: participant.firstname,
                  lastname: participant.lastname,
                }
              );
          if (!this.validationErrors.includes(invalidEmailAddressError)) {
            this.validationErrors.push(invalidEmailAddressError);
          }
        }
        // if (!this.validPhoneNumber(participant.phoneNumber)) {
        //   const invalidPhoneNumberError = !isAccompanyingParticipant
        //     ? this.$t("createReservationView.validationErrorInvalidPhoneNumber")
        //     : this.$t(
        //         "createReservationView.validationErrorAccompanyingParticipantInvalidPhoneNumber",
        //         {
        //           firstname: participant.firstname,
        //           lastname: participant.lastname,
        //         }
        //       );
        //   if (!this.validationErrors.includes(invalidPhoneNumberError)) {
        //     this.validationErrors.push(invalidPhoneNumberError);
        //   }
        // }
      }
    },
    validEmail(email) {
      var regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(email);
    },
    // validPhoneNumber(phoneNumber) {
    //   return PhoneNumber(phoneNumber, "CH").isValid();
    // },
    createReservation() {
      this.reservationInProgress = true;

      this.$apollo
        .mutate({
          mutation: CreateReservationMutation,
          variables: {
            input: {
              timeSlot: { link: this.selectedTimeSlotID },
              participant: {
                create: this.participant,
              },
              accompanyingParticipants: {
                create: this.accompanyingParticipants,
              },
              language: this.$i18n.locale,
              timeZone: this.timeZone,
            },
          },
          update: (store, { data: { createReservation } }) => {
            if (createReservation) {
              const data = store.readQuery({
                query: ReservationsQuery,
                variables: {
                  eventKey: this.event.key,
                  date: new Date(this.timeSlot.date),
                },
              });
              const reservations = cloneDeep(data.reservations);
              reservations.push(createReservation);
              store.writeQuery({
                query: ReservationsQuery,
                variables: {
                  eventKey: this.event.key,
                  date: new Date(this.timeSlot.date),
                },
                data: { reservations },
              });
            }

            const timeSlotData = store.readQuery({
              query: TimeSlotByIDQuery,
              variables: {
                query: { _id: this.timeSlot._id },
              },
            });
            const timeSlot = cloneDeep(timeSlotData.timeSlot);
            timeSlot.availableParticipants =
              timeSlot.availableParticipants -
              (this.accompanyingParticipants.length + 1);
            store.writeQuery({
              query: TimeSlotByIDQuery,
              variables: {
                query: { _id: this.timeSlot._id },
              },
              data: { timeSlot },
            });
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.createCalendarEntry(data.createReservation._editCode);
          this.$emit("reservation", {
            calendar: this.icalendar,
            editMode: false,
            cancelMode: false,
          });
          this.cancel();
          this.reservationInProgress = false;
        })
        .catch((error) => {
          this.createReservationMutationError = error;
          this.reservationInProgress = false;
        });
    },
    updateReservation() {
      this.reservationInProgress = true;

      // See if and what accompanying participants for the reservation have been added.
      // this.reservationToEdit.accompanyingParticipants = original, unmodified
      // this.accompanyingParticipants = copy, potentially modified
      const accompanyingParticipantsToAdd =
        this.accompanyingParticipants.filter(
          (participant) => participant._id === undefined
        );
      const accompanyingParticipantsExistingIDs = this.accompanyingParticipants
        .filter((participant) => participant._id !== undefined)
        .map((a) => a._id);

      this.runReservationUpdateMutations(
        accompanyingParticipantsToAdd,
        accompanyingParticipantsExistingIDs
      ).catch((error) => {
        this.updateReservationMutationError = error;
      });
    },
    async runReservationUpdateMutations(
      accompanyingParticipantsToAdd,
      accompanyingParticipantsExistingIDs
    ) {
      let accompanyingParticipantsToAddIDs;

      if (accompanyingParticipantsToAdd.length > 0) {
        let response = await this.$apollo.mutate({
          mutation: CreateParticipantsMutation,
          variables: {
            data: accompanyingParticipantsToAdd,
          },
        });
        accompanyingParticipantsToAddIDs =
          response.data.insertManyParticipants.insertedIds;
      }

      let accompanyingParticipantsLinks = [];
      if (accompanyingParticipantsToAdd.length == 0) {
        accompanyingParticipantsLinks = accompanyingParticipantsExistingIDs;
      } else if (accompanyingParticipantsToAdd.length > 0) {
        accompanyingParticipantsLinks = [
          ...accompanyingParticipantsExistingIDs,
          ...accompanyingParticipantsToAddIDs,
        ];
      }

      this.$apollo
        .mutate({
          mutation: UpdateReservationMutation,
          variables: {
            input: {
              editCode: this.reservationToEdit._editCode,
              timeSlot: this.selectedTimeSlotID,
              accompanyingParticipants: accompanyingParticipantsLinks,
            },
          },
          update: (store, { data: { updateReservation } }) => {
            // Check if selected time-slot for the reservation has changed.
            if (
              this.selectedTimeSlotID === this.reservationToEdit.timeSlot._id
            ) {
              // The time-slot hasn't changed, add the difference in accompanying participants to the available participants count.
              const timeSlotData = store.readQuery({
                query: TimeSlotByIDQuery,
                variables: {
                  query: { _id: this.timeSlot._id },
                },
              });
              const timeSlot = cloneDeep(timeSlotData.timeSlot);

              const updateReservationClone = cloneDeep(updateReservation);
              updateReservationClone.__typename = "Reservation";
              updateReservation = updateReservationClone;

              const accompanyingParticipantsDifference =
                this.reservationToEdit.accompanyingParticipants.length -
                updateReservation.accompanyingParticipants.length;
              timeSlot.availableParticipants =
                timeSlot.availableParticipants +
                accompanyingParticipantsDifference;

              store.writeQuery({
                query: TimeSlotByIDQuery,
                variables: {
                  query: { _id: this.timeSlot._id },
                },
                data: { timeSlot },
              });

              // Update the available reservation
              const reservationsData = store.readQuery({
                query: ReservationsQuery,
                variables: {
                  eventKey: this.event.key,
                  date: new Date(this.timeSlot.date),
                },
              });
              const reservationsDataClone = cloneDeep(reservationsData);
              const index = reservationsDataClone.reservations.findIndex(
                (reservation) => reservation._id === this.reservationToEdit._id
              );
              reservationsDataClone.reservations[index] = updateReservation;
              store.writeQuery({
                query: ReservationsQuery,
                variables: {
                  eventKey: this.event.key,
                  date: new Date(this.timeSlot.date),
                },
                data: reservationsDataClone,
              });
            } else {
              // The time-slot has changed.
              // Substract modified number of slots from the new time-slot.
              // (because the number of accompanying participants could have changed)
              const newTimeSlotData = store.readQuery({
                query: TimeSlotByIDQuery,
                variables: {
                  query: { _id: this.selectedTimeSlotID },
                },
              });
              const newTimeSlotDataClone = cloneDeep(newTimeSlotData);

              newTimeSlotDataClone.timeSlot.availableParticipants =
                newTimeSlotDataClone.timeSlot.availableParticipants -
                (updateReservation.accompanyingParticipants.length + 1);

              store.writeQuery({
                query: TimeSlotByIDQuery,
                variables: {
                  query: { _id: this.selectedTimeSlotID },
                },
                data: newTimeSlotDataClone,
              });

              // Add initial, unmodified number of slots to the previous time-slot.
              const previousTimeSlotData = store.readQuery({
                query: TimeSlotByIDQuery,
                variables: {
                  query: { _id: this.reservationToEdit.timeSlot._id },
                },
              });
              const previousTimeSlotDataClone = cloneDeep(previousTimeSlotData);

              previousTimeSlotDataClone.timeSlot.availableParticipants =
                previousTimeSlotDataClone.timeSlot.availableParticipants +
                (this.reservationToEdit.accompanyingParticipants.length + 1);

              store.writeQuery({
                query: TimeSlotByIDQuery,
                variables: {
                  query: { _id: this.reservationToEdit.timeSlot._id },
                },
                data: previousTimeSlotDataClone,
              });

              // Add the modified reservation to the new timeSlot query.
              const newReservationsData = store.readQuery({
                query: ReservationsQuery,
                variables: {
                  eventKey: this.event.key,
                  date: new Date(this.timeSlot.date),
                },
              });
              const newReservationsDataClone = cloneDeep(newReservationsData);
              newReservationsDataClone.reservations.push(updateReservation);
              store.writeQuery({
                query: ReservationsQuery,
                variables: {
                  eventKey: this.event.key,
                  date: new Date(this.timeSlot.date),
                },
                data: newReservationsDataClone,
              });

              // Remove the modified reservation from the old timeSlot query.
              const previousReservationsData = store.readQuery({
                query: ReservationsQuery,
                variables: {
                  eventKey: this.event.key,
                  date: new Date(this.reservationToEdit.timeSlot.date),
                },
              });
              const previousReservationsDataClone = cloneDeep(
                previousReservationsData
              );
              const index =
                previousReservationsDataClone.reservations.findIndex(
                  (reservation) => reservation._id === updateReservation._id
                );
              previousReservationsDataClone.reservations.splice(index, 1);
              store.writeQuery({
                query: ReservationsQuery,
                variables: {
                  eventKey: this.event.key,
                  date: new Date(this.reservationToEdit.timeSlot.date),
                },
                data: previousReservationsDataClone,
              });
            }
          },
        })
        .then(({ data }) => {
          this.createCalendarEntry(data.updateReservation._editCode);
          this.$emit("reservation", {
            calendar: this.icalendar,
            editMode: true,
            cancelMode: false,
          });
          this.cancel();
          this.reservationInProgress = false;
        })
        .catch((error) => {
          this.updateReservationMutationError = error;
          this.reservationInProgress = false;
        });
    },
    cancelReservation() {
      this.reservationCancellationInProgress = true;

      this.$apollo
        .mutate({
          mutation: CancelReservationMutation,
          variables: {
            input: this.reservationToEdit._editCode,
          },
          update: (store) => {
            // Remove reservation from query.
            const reservationsData = store.readQuery({
              query: ReservationsQuery,
              variables: {
                eventKey: this.event.key,
                date: new Date(this.reservationToEdit.timeSlot.date),
              },
            });
            const reservationsDataClone = cloneDeep(reservationsData);
            const index = reservationsDataClone.reservations.findIndex(
              (reservation) => reservation._id === this.reservationToEdit._id
            );
            reservationsDataClone.reservations.splice(index, 1);
            store.writeQuery({
              query: ReservationsQuery,
              variables: {
                eventKey: this.event.key,
                date: new Date(this.reservationToEdit.timeSlot.date),
              },
              data: reservationsDataClone,
            });

            // Update available participants for time slot.
            const timeSlotsData = store.readQuery({
              query: TimeSlotsQuery,
              variables: {
                query: {
                  event: {
                    key: this.event.key,
                  },
                  date: new Date(this.reservationToEdit.timeSlot.date),
                },
              },
            });

            const timeSlotsDataClone = cloneDeep(timeSlotsData);
            const timeSlotIndex = timeSlotsDataClone.timeSlots.findIndex(
              (timeSlot) => timeSlot._id === this.reservationToEdit.timeSlot._id
            );
            const originalAvailableParticipants =
              timeSlotsDataClone.timeSlots[timeSlotIndex].availableParticipants;
            const modifiedAvailableParticipants =
              originalAvailableParticipants +
              (this.reservationToEdit.accompanyingParticipants.length + 1);
            timeSlotsDataClone.timeSlots[timeSlotIndex].availableParticipants =
              modifiedAvailableParticipants;
            store.writeQuery({
              query: TimeSlotsQuery,
              variables: {
                query: {
                  event: {
                    key: this.event.key,
                  },
                  date: new Date(this.reservationToEdit.timeSlot.date),
                },
              },
              data: timeSlotsDataClone,
            });
          },
        })
        .then(() => {
          this.$emit("reservation", {
            calendar: this.icalendar,
            editMode: false,
            cancelMode: true,
          });
          this.cancel();
          this.cancelReservationModalVisible = false;
          this.reservationCancellationInProgress = false;
        })
        .catch((error) => {
          this.cancelReservationMutationError = error;
          this.reservationCancellationInProgress = false;
        });
    },
    createCalendarEntry(editCode) {
      this.icalendar = new ICalendar({
        title: this.$t("calendarEntry.title"),
        location: `${this.timeSlot.location.address}\\, ${
          this.timeSlot.location.postalCode
        } ${this.localizedText(
          this.timeSlot.location.name
        )}\\, ${this.localizedText(this.timeSlot.event.name)}`,
        description: this.$t("calendarEntry.description", {
          link: `https://on-tour.belimo.com/reservation/${editCode}`,
        }),
        start: new Date(this.timeSlot.startTimestamp),
        end: new Date(this.timeSlot.endTimestamp),
      });
      this.icalendar.addAlarm({
        action: "DISPLAY",
        description: "Erinnerung",
        summary: "Summary",
        trigger: {
          minutes: 1440,
        },
      });
    },
    cancel() {
      this.$store.commit("setSelectedTimeSlotID", null);
      this.$store.commit("setReservationToEdit", null);
      this.$store.commit(
        "setReservationCurrentlyAccompanyingParticipants",
        null
      );
    },
  },
};
</script>

<style lang="less" scoped>
.create-reservation-view h4 {
  margin-top: @size48;
}
.warning {
  color: @go-color-blue[light];
}
.warning-time-slot-full {
  margin-bottom: @size16;
}
</style>
