<template>
  <div id="bookingCalendar" class="calendar">
    <div class="calendar-header">
      <Button appearance="subtle" @click="changeCalendarMonth(-1)">
        <template #iconAfter>
          <font-awesome-icon :icon="iconChevronLeft" />
        </template>
      </Button>
      <time class="calendar-header-month-indicator">
        {{ $t(`bookingCalendar.months[${state.month}]`) }} {{ state.year }}
      </time>
      <Button
        appearance="default"
        class="calendar-header-button-today"
        @click="jumpToToday()"
        >{{ $t("bookingCalendar.buttonToday") }}</Button
      >
      <Button appearance="subtle" @click="changeCalendarMonth(+1)">
        <template #iconAfter>
          <font-awesome-icon :icon="iconChevronRight" />
        </template>
      </Button>
    </div>
    <div class="calendar-days-of-week">
      <div
        v-for="(dayOfWeek, index) in $t(`bookingCalendar.daysOfWeek`)"
        :key="index"
        class="text-caption-bold"
      >
        {{ dayOfWeek }}
      </div>
    </div>
    <div class="calendar-date-grid">
      <button
        v-for="date in datesForGrid(state.year, state.month)"
        :key="date.key.toUTCString()"
        :class="{
          'calendar-date-today': date.today,
          'calendar-date-subtle': !date.currentMonth,
          selected: date.key.getTime() == internalSelectedDate.getTime(),
          highlighted: highlightedDates.some(
            (highlightedDate) =>
              highlightedDate.getTime() === date.key.getTime()
          ),
          'colored-highlighted': coloredHighlightedDates.some(
            (highlightedDate) =>
              highlightedDate.getTime() === date.key.getTime()
          ),
        }"
        @click="internalSelectedDate = new Date(date.key)"
      >
        <time :datetime="date.key.toUTCString()">{{ date.date }}</time>
      </button>
    </div>
    <div class="calendar-legend">
      <div class="calendar-legend-entry">
        <span class="calendar-legend-entry-circle circle-available"></span>
        <span>{{ $t("bookingCalendar.legendAvailable") }}</span>
      </div>
      <div class="calendar-legend-entry">
        <span class="calendar-legend-entry-circle circle-highlighted"></span>
        <span>{{ $t("bookingCalendar.legendHighlighted") }}</span>
      </div>
      <div class="calendar-legend-entry">
        <span class="calendar-legend-entry-circle circle-selected"></span>
        <span>{{ $t("bookingCalendar.legendSelected") }}</span>
      </div>
      <div class="calendar-legend-entry">
        <span class="calendar-legend-entry-circle circle-today"></span>
        <span>{{ $t("bookingCalendar.legendToday") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { mapState } from "vuex";
import Button from "@gospore/gospore-web-ui-library-button";

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export default {
  name: "BookingCalendar",
  components: {
    Button,
    FontAwesomeIcon,
  },
  model: {
    prop: "selectedDate",
    event: "date-selected",
  },
  props: {
    selectedDate: {
      type: Date,
      default: new Date(
        Date.UTC(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      ),
    },
    highlightedDates: {
      type: Array,
      default: () => [
        new Date("2021-07-13"),
        new Date("2021-07-14"),
        new Date("2021-07-15"),
        new Date("2021-07-16"),
        new Date("2021-07-17"),
      ],
    },
    coloredHighlightedDates: {
      type: Array,
      default: () => [
        new Date("2021-07-13"),
        new Date("2021-07-14"),
        new Date("2021-07-15"),
        new Date("2021-07-16"),
        new Date("2021-07-17"),
      ],
    },
  },
  data() {
    return {
      state: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      internalSelectedDate: this.selectedDate,
      iconChevronLeft: faChevronLeft,
      iconChevronRight: faChevronRight,
    };
  },
  computed: {
    // coloredHighlightedDates() {
    //   var coloredHighlightedDates = [];

    //   if (this.selectedLocation) {
    //     var currentDate = new Date(this.selectedLocation.startDate);

    //     while (currentDate <= new Date(this.selectedLocation.endDate)) {
    //       coloredHighlightedDates.push(new Date(currentDate));
    //       currentDate = currentDate.addDays(1);
    //     }
    //   }

    //   return coloredHighlightedDates;
    // },
    ...mapState(["selectedLocation"]),
  },
  watch: {
    selectedDate() {
      this.internalSelectedDate = this.selectedDate;
    },
    internalSelectedDate() {
      this.$emit("date-selected", this.internalSelectedDate);
      this.state.year = this.internalSelectedDate.getFullYear();
      this.state.month = this.internalSelectedDate.getMonth();
    },
    selectedLocation() {
      if (
        this.selectedLocation &&
        (this.selectedLocation.startDate ||
          this.selectedLocation.endDate ||
          (this.selectedLocation.startDate && this.selectedLocation.endDate))
      ) {
        let selectedLocationStartDate = new Date(
          this.selectedLocation.startDate
        );
        this.state.year = selectedLocationStartDate.getFullYear();
        this.state.month = selectedLocationStartDate.getMonth();
        this.internalSelectedDate = selectedLocationStartDate;
      }
    },
  },
  methods: {
    changeCalendarMonth(prevNextIndicator) {
      let date = new Date(
        this.state.year,
        this.state.month + prevNextIndicator
      );

      this.state.year = date.getFullYear();
      this.state.month = date.getMonth();
    },
    jumpToToday() {
      let todayDate = new Date(
        Date.UTC(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      );
      this.state.year = todayDate.getFullYear();
      this.state.month = todayDate.getMonth();
      this.internalSelectedDate = todayDate;
    },
    datesForGrid(year, month) {
      var dates = [];
      let firstDay = new Date(this.state.year, this.state.month).getDay();
      let totalDaysInMonth = new Date(year, month + 1, 0).getDate();
      let totalDaysInPrevMonth = new Date(year, month, 0).getDate();

      // Add previous months days
      for (var previousCount = 1; previousCount <= firstDay; previousCount++) {
        var previousMonthDate = totalDaysInPrevMonth - firstDay + previousCount;
        var previousKey = new Date(
          Date.UTC(year, month - 1, previousMonthDate)
        );
        dates.push({ key: previousKey, date: previousMonthDate });
      }

      // Add current months days
      let today = new Date();
      for (
        var currentCount = 1;
        currentCount <= totalDaysInMonth;
        currentCount++
      ) {
        var currentKey = new Date(Date.UTC(year, month, currentCount));
        if (
          currentCount === today.getDate() &&
          month === today.getMonth() &&
          year === today.getFullYear()
        ) {
          dates.push({
            key: currentKey,
            date: currentCount,
            currentMonth: true,
            today: true,
          });
        } else {
          dates.push({
            key: currentKey,
            date: currentCount,
            currentMonth: true,
          });
        }
      }

      // Add next months days
      let gridsize = 42;
      if (dates.length < gridsize) {
        var count = gridsize - dates.length;
        for (var nextCount = 1; nextCount <= count; nextCount++) {
          var nextKey = new Date(Date.UTC(year, month + 1, nextCount));
          dates.push({ key: nextKey, date: nextCount });
        }
      }

      return dates;
    },
  },
};
</script>

<style lang="less" scoped>
.calendar-header {
  display: flex;
  text-align: center;
  font-weight: @font-weight-bold;
}
.calendar-header > {
  align-items: baseline;
}
.calendar-header-button-today {
  margin-right: @size8;
}
.calendar-header-month-indicator {
  flex-basis: 100%;
}
.calendar-days-of-week,
.calendar-date-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  grid-gap: @size4;
}
.calendar-days-of-week {
  margin-top: @size16;
}
.calendar-days-of-week > * {
  color: gray;
}
.calendar-date-grid {
  margin-top: @size8;
}
.calendar-date-grid button {
  position: relative;
  border: 0 none;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.1s ease-out;
  padding: 0;
  font-weight: @font-weight-semibold;
}
@media only screen and (max-width: 330px) {
  .calendar-date-grid button {
    width: 40px !important;
    height: 40px !important;
  }
}
.calendar-date-grid button:hover,
.calendar-date-grid button:focus {
  background-color: @go-color-n30;
  color: @go-color-n500;
}
.calendar-date-grid button:active {
  background-color: fade(@go-color-b75, 60%);
  color: @go-color-b400;
}
.calendar-date-grid button.highlighted {
  background-color: @go-color-n40;
  font-weight: @font-weight-bold;
}
.calendar-date-grid button.highlighted.selected {
  border: @borderWidth solid @go-color-n40 !important;
}
.calendar-date-grid button.colored-highlighted {
  background-color: @color-primary;
  color: @go-color-n0;
  font-weight: @font-weight-bold;
}
.calendar-date-grid button.selected.colored-highlighted {
  background-color: @color-primary;
  border: @borderWidth solid @go-color-n700 !important;
}
.calendar-date-grid button.selected {
  background-color: @go-color-n700;
  color: @go-color-n20;
}
.calendar-date-today {
  border: @borderWidth solid #ff0000 !important;
}
.calendar-date-today.selected {
  background-color: #ff0000 !important;
  border: @borderWidth solid @go-color-n700 !important;
}
.calendar-date-subtle {
  color: @go-color-n70 !important;
  font-weight: @font-weight-regular !important;
}
.calendar-date-subtle.colored-highlighted {
  background-color: @color-primary !important;
  color: @go-color-n0 !important;
  font-weight: @font-weight-bold !important;
}
.calendar-legend {
  margin-top: @size32;
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 820px) {
  .calendar-legend {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: @size4;
  }
}
@media only screen and (max-width: 470px) {
  .calendar-legend {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: @size4;
  }
}
.calendar-legend-entry {
  display: flex;
  align-items: center;
  margin-right: @size32;
  margin-bottom: @size4;
  color: gray;
  font-size: @font-size-12;
  line-height: normal;
}
.calendar-legend-entry-circle {
  height: 18px;
  width: 18px;
  min-width: 18px;
  border-radius: 50%;
  display: inline-block;
  margin-right: @size12;
}
.circle-available {
  background-color: @go-color-n40;
}
.circle-highlighted {
  background-color: @color-primary;
}
.circle-selected {
  background-color: @go-color-n700;
}
.circle-today {
  background-color: #ff0000;
}
</style>
