var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar",attrs:{"id":"bookingCalendar"}},[_c('div',{staticClass:"calendar-header"},[_c('Button',{attrs:{"appearance":"subtle"},on:{"click":function($event){return _vm.changeCalendarMonth(-1)}},scopedSlots:_vm._u([{key:"iconAfter",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":_vm.iconChevronLeft}})]},proxy:true}])}),_c('time',{staticClass:"calendar-header-month-indicator"},[_vm._v(" "+_vm._s(_vm.$t(("bookingCalendar.months[" + (_vm.state.month) + "]")))+" "+_vm._s(_vm.state.year)+" ")]),_c('Button',{staticClass:"calendar-header-button-today",attrs:{"appearance":"default"},on:{"click":function($event){return _vm.jumpToToday()}}},[_vm._v(_vm._s(_vm.$t("bookingCalendar.buttonToday")))]),_c('Button',{attrs:{"appearance":"subtle"},on:{"click":function($event){return _vm.changeCalendarMonth(+1)}},scopedSlots:_vm._u([{key:"iconAfter",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":_vm.iconChevronRight}})]},proxy:true}])})],1),_c('div',{staticClass:"calendar-days-of-week"},_vm._l((_vm.$t("bookingCalendar.daysOfWeek")),function(dayOfWeek,index){return _c('div',{key:index,staticClass:"text-caption-bold"},[_vm._v(" "+_vm._s(dayOfWeek)+" ")])}),0),_c('div',{staticClass:"calendar-date-grid"},_vm._l((_vm.datesForGrid(_vm.state.year, _vm.state.month)),function(date){return _c('button',{key:date.key.toUTCString(),class:{
        'calendar-date-today': date.today,
        'calendar-date-subtle': !date.currentMonth,
        selected: date.key.getTime() == _vm.internalSelectedDate.getTime(),
        highlighted: _vm.highlightedDates.some(
          function (highlightedDate) { return highlightedDate.getTime() === date.key.getTime(); }
        ),
        'colored-highlighted': _vm.coloredHighlightedDates.some(
          function (highlightedDate) { return highlightedDate.getTime() === date.key.getTime(); }
        ),
      },on:{"click":function($event){_vm.internalSelectedDate = new Date(date.key)}}},[_c('time',{attrs:{"datetime":date.key.toUTCString()}},[_vm._v(_vm._s(date.date))])])}),0),_c('div',{staticClass:"calendar-legend"},[_c('div',{staticClass:"calendar-legend-entry"},[_c('span',{staticClass:"calendar-legend-entry-circle circle-available"}),_c('span',[_vm._v(_vm._s(_vm.$t("bookingCalendar.legendAvailable")))])]),_c('div',{staticClass:"calendar-legend-entry"},[_c('span',{staticClass:"calendar-legend-entry-circle circle-highlighted"}),_c('span',[_vm._v(_vm._s(_vm.$t("bookingCalendar.legendHighlighted")))])]),_c('div',{staticClass:"calendar-legend-entry"},[_c('span',{staticClass:"calendar-legend-entry-circle circle-selected"}),_c('span',[_vm._v(_vm._s(_vm.$t("bookingCalendar.legendSelected")))])]),_c('div',{staticClass:"calendar-legend-entry"},[_c('span',{staticClass:"calendar-legend-entry-circle circle-today"}),_c('span',[_vm._v(_vm._s(_vm.$t("bookingCalendar.legendToday")))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }