var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"event-row",attrs:{"to":{
    name: 'EventView',
    params: { eventKey: _vm.event.key },
  }}},[_c('h5',[_vm._v(_vm._s(_vm.localizedText(_vm.event.name)))]),(_vm.user && _vm.user.role === 'admin')?_c('div',[_c('div',{staticClass:"event-row-actions"},[_c('DropdownMenu',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
  var triggerID = ref.triggerID;
  var toggleOpen = ref.toggleOpen;
  var selected = ref.selected;
return [_c('Button',{staticClass:"button-actions",attrs:{"id":triggerID,"selected":selected},on:{"click":function($event){$event.preventDefault();return toggleOpen.apply(null, arguments)}}},[_vm._v(" ... ")])]}}],null,false,1981107199)},[_c('DropdownItemGroup',{attrs:{"title":_vm.$t('shared.dropdownGroupAdministration')}},[_c('DropdownItem',{on:{"click":function($event){_vm.createEventModalVisible = true}}},[_vm._v(" "+_vm._s(_vm.$t("shared.dropdownItemEdit"))+" ")]),_c('DropdownItem',{on:{"click":function($event){_vm.deleteEventModalVisible = true}}},[_vm._v(" "+_vm._s(_vm.$t("shared.dropdownItemDelete"))+" ")])],1)],1)],1),_c('CreateEventModal',{attrs:{"edit-mode":true,"event":_vm.event},model:{value:(_vm.createEventModalVisible),callback:function ($$v) {_vm.createEventModalVisible=$$v},expression:"createEventModalVisible"}})],1):_vm._e(),_c('Modal',{attrs:{"close-on-overlay-click":!_vm.eventDeletionInProgress},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('ModalHeader',{attrs:{"title":_vm.$t('eventView.deleteEventModalTitle')}})]},proxy:true},{key:"footer",fn:function(){return [_c('ModalFooter',[_c('ButtonGroup',[_c('Button',{attrs:{"disabled":_vm.eventDeletionInProgress,"appearance":"danger"},on:{"click":function($event){return _vm.deleteEvent()}}},[_vm._v(" "+_vm._s(_vm.$t("shared.dropdownItemDelete"))+" ")]),_c('Button',{attrs:{"disabled":_vm.eventDeletionInProgress,"appearance":"subtle"},on:{"click":function($event){_vm.deleteEventModalVisible = false}}},[_vm._v(" "+_vm._s(_vm.$t("shared.buttonCancel"))+" ")])],1)],1)]},proxy:true}]),model:{value:(_vm.deleteEventModalVisible),callback:function ($$v) {_vm.deleteEventModalVisible=$$v},expression:"deleteEventModalVisible"}},[_c('p',[_vm._v(_vm._s(_vm.$t("eventView.deleteEventModalDescription")))]),(_vm.deleteEventMutationError)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.deleteEventMutationError.message)+" ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }