<template>
  <Modal
    v-model="createTimeSlotsModalVisible"
    :close-on-overlay-click="!timeSlotCreationInProgress"
    :allow-outside-click="true"
  >
    <Form @submit="validateForm()">
      <FormHeader
        :title="
          !editMode
            ? $t('createTimeSlotsModal.titleCreate')
            : $t('createTimeSlotsModal.titleEdit')
        "
      />
      <FormSection
        :description="!editMode ? $t('createTimeSlotsModal.description') : ''"
      >
        <Textfield
          id="date"
          :value="
            date.toLocaleDateString($i18n.locale, {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
          "
          :placeholder="$t('createTimeSlotsModal.textfieldPlaceholderDate')"
          :label="$t('createTimeSlotsModal.textfieldLabelDate')"
          :disabled="true"
        />
        <Select
          v-slot="{ query, inputFocused }"
          :placeholder="$t('createTimeSlotsModal.selectPlaceholderLocation')"
          :label="$t('createTimeSlotsModal.selectLabelLocation')"
          :text="selectedLocationDescription"
          :disabled="editMode"
        >
          <p v-if="!locations.length" class="locations-empty">
            {{ $t("createTimeSlotsModal.locationsEmpty") }}
          </p>
          <DropdownItemGroup
            v-else
            :filter="query"
            :input-focused="inputFocused"
          >
            <DropdownItem
              v-for="location in locations"
              :key="location._id"
              :value="localizedText(location.name)"
              :selected="selectLocation === location._id"
              @click="selectLocation = location._id"
            >
              {{ localizedText(location.name) }}
            </DropdownItem>
          </DropdownItemGroup>
        </Select>
        <p v-html="$t('createTimeSlotsModal.descriptionTimestamp')" />
        <Textfield
          id="startTimestamp"
          v-model="startTimestamp"
          :placeholder="
            $t('createTimeSlotsModal.textfieldPlaceholderStartTimestamp')
          "
          :label="$t('createTimeSlotsModal.textfieldLabelStartTimestamp')"
          :disabled="editMode"
        />
        <Textfield
          id="endTimestamp"
          v-model="endTimestamp"
          :placeholder="
            $t('createTimeSlotsModal.textfieldPlaceholderEndTimestamp')
          "
          :label="$t('createTimeSlotsModal.textfieldLabelEndTimestamp')"
          :disabled="editMode"
        />
        <Textfield
          id="duration"
          v-model.number="duration"
          type="number"
          min="5"
          :placeholder="$t('createTimeSlotsModal.textfieldPlaceholderDuration')"
          :label="$t('createTimeSlotsModal.textfieldLabelDuration')"
          :disabled="editMode"
        />
        <Textfield
          v-if="!editMode"
          id="break"
          v-model.number="slotBreak"
          type="number"
          min="0"
          :placeholder="
            $t('createTimeSlotsModal.textfieldPlaceholderSlotBreak')
          "
          :label="$t('createTimeSlotsModal.textfieldLabelSlotBreak')"
          :disabled="editMode"
        />
        <Textfield
          id="maxParticipants"
          v-model.number="maxParticipants"
          type="number"
          min="1"
          :placeholder="
            $t('createTimeSlotsModal.textfieldPlaceholderMaxParticipants')
          "
          :label="$t('createTimeSlotsModal.textfieldLabelMaxParticipants')"
        />
      </FormSection>
      <FormSection
        v-if="
          validationErrors.length ||
          locationsQueryError ||
          createTimeSlotsMutationError ||
          updateTimeSlotMutationError
        "
      >
        <div v-if="validationErrors.length">
          <b v-t="'shared.validationErrorDescription'" />
          <ul>
            <li
              v-for="validationError in validationErrors"
              :key="validationError"
            >
              <p>{{ validationError }}</p>
            </li>
          </ul>
        </div>
        <p v-if="locationsQueryError" class="error">
          {{ locationsQueryError.message }}
        </p>
        <p v-if="createTimeSlotsMutationError" class="error">
          {{ createTimeSlotsMutationError.message }}
        </p>
        <p v-if="updateTimeSlotMutationError" class="error">
          {{ updateTimeSlotMutationError.message }}
        </p>
      </FormSection>
      <FormFooter>
        <ButtonGroup>
          <Button
            :disabled="timeSlotCreationInProgress"
            type="submit"
            appearance="primary"
            >{{
              !editMode ? $t("shared.buttonCreate") : $t("shared.buttonSave")
            }}</Button
          >
          <Button
            :disabled="timeSlotCreationInProgress"
            appearance="subtle"
            @click="createTimeSlotsModalVisible = false"
          >
            {{ $t("shared.buttonCancel") }}
          </Button>
        </ButtonGroup>
      </FormFooter>
    </Form>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
const cloneDeep = require("lodash.clonedeep");
import Modal from "@gospore/gospore-web-ui-library-modal";
import Form, {
  FormHeader,
  FormSection,
  FormFooter,
} from "@gospore/gospore-web-ui-library-form";
import Select from "@gospore/gospore-web-ui-library-select";
import {
  DropdownItemGroup,
  DropdownItem,
} from "@gospore/gospore-web-ui-library-dropdown-menu";
import Textfield from "@gospore/gospore-web-ui-library-textfield";
import Button, { ButtonGroup } from "@gospore/gospore-web-ui-library-button";

import LocationsQuery from "@/graphql/LocationsQuery.gql";
import CreateTimeSlotsMutation from "@/graphql/CreateTimeSlotsMutation.gql";
import TimeSlotsQuery from "@/graphql/TimeSlotsQuery.gql";
import UpdateTimeSlotMutation from "@/graphql/UpdateTimeSlotMutation.gql";

export default {
  name: "CreateTimeSlotsModal",
  components: {
    Modal,
    Form,
    FormHeader,
    FormSection,
    FormFooter,
    Select,
    DropdownItemGroup,
    DropdownItem,
    Textfield,
    Button,
    ButtonGroup,
  },
  model: {
    prop: "visible",
    event: "visible-changed",
  },
  props: {
    date: {
      type: Date,
      default: null,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    timeSlot: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locations: [],
      selectLocation: !this.editMode
        ? this.$store.state.selectedLocation
          ? this.$store.state.selectedLocation._id
          : null
        : this.timeSlot.location._id,
      startTimestamp: !this.editMode
        ? `${this.date.getFullYear()}-${(
            "0" +
            (this.date.getMonth() + 1)
          ).slice(-2)}-${("0" + this.date.getDate()).slice(-2)}T08:00+02:00`
        : this.timeSlot.startTimestamp,
      endTimestamp: !this.editMode
        ? `${this.date.getFullYear()}-${(
            "0" +
            (this.date.getMonth() + 1)
          ).slice(-2)}-${("0" + this.date.getDate()).slice(-2)}T12:00+02:00`
        : this.timeSlot.endTimestamp,
      duration: !this.editMode
        ? 30
        : (new Date(this.timeSlot.endTimestamp).getTime() -
            new Date(this.timeSlot.startTimestamp).getTime()) /
          1000 /
          60,
      slotBreak: 15,
      maxParticipants: !this.editMode ? 1 : this.timeSlot.maxParticipants,
      createTimeSlotsModalVisible: this.visible,
      validationErrors: [],
      locationsQueryError: null,
      createTimeSlotsMutationError: null,
      updateTimeSlotMutationError: null,
      timeSlotCreationInProgress: false,
    };
  },
  computed: {
    selectedLocationDescription() {
      if (this.selectLocation && this.locations) {
        let location = this.locations.find(
          (location) => location._id === this.selectLocation
        );
        return location ? this.localizedText(location.name) : "";
      } else {
        return "";
      }
    },
    timeSpan() {
      return (
        (new Date(this.endTimestamp).getTime() -
          new Date(this.startTimestamp).getTime()) /
        1000 /
        60
      );
    },
    ...mapState(["event", "selectedLocation"]),
    ...mapGetters(["localizedText"]),
  },
  watch: {
    createTimeSlotsModalVisible() {
      this.$emit("visible-changed", this.createTimeSlotsModalVisible);

      if (this.createTimeSlotsModalVisible && !this.selectLocation) {
        this.selectLocation = this.selectedLocation
          ? this.selectedLocation._id
          : null;
      }
    },
    visible() {
      this.createTimeSlotsModalVisible = this.visible;
    },
    date() {
      if (!this.editMode) {
        this.startTimestamp = `${this.date.getFullYear()}-${(
          "0" +
          (this.date.getMonth() + 1)
        ).slice(-2)}-${("0" + this.date.getDate()).slice(-2)}T08:00+02:00`;
        this.endTimestamp = `${this.date.getFullYear()}-${(
          "0" +
          (this.date.getMonth() + 1)
        ).slice(-2)}-${("0" + this.date.getDate()).slice(-2)}T12:00+02:00`;
      }
    },
  },
  methods: {
    validateForm() {
      this.validationErrors = [];

      if (
        !this.selectLocation ||
        !this.startTimestamp ||
        this.startTimestamp === "" ||
        !this.endTimestamp ||
        this.endTimestamp === "" ||
        this.duration <= 0 ||
        this.slotBreak < 0 ||
        !this.maxParticipants ||
        this.maxParticipants <= 0
      ) {
        const missingNeccessaryFieldError = this.$t(
          "shared.validationErrorMissingNeccessaryField"
        );
        if (!this.validationErrors.includes(missingNeccessaryFieldError)) {
          this.validationErrors.push(missingNeccessaryFieldError);
        }
      } else {
        if (!this.validTimestamp(this.startTimestamp)) {
          const invalidStartTimestampError = this.$t(
            "createTimeSlotsModal.validationErrorInvalidStartTimestamp"
          );
          if (!this.validationErrors.includes(invalidStartTimestampError)) {
            this.validationErrors.push(invalidStartTimestampError);
          }
        }
        if (!this.validTimestamp(this.endTimestamp)) {
          const invalidEndTimestampError = this.$t(
            "createTimeSlotsModal.validationErrorInvalidEndTimestamp"
          );
          if (!this.validationErrors.includes(invalidEndTimestampError)) {
            this.validationErrors.push(invalidEndTimestampError);
          }
        }
        // Check if the start time is past the end time.
        if (new Date(this.endTimestamp) < new Date(this.startTimestamp)) {
          const startTimePastEndTimeError = this.$t(
            "createTimeSlotsModal.validationErrorStartTimePastEndTime"
          );
          if (!this.validationErrors.includes(startTimePastEndTimeError)) {
            this.validationErrors.push(startTimePastEndTimeError);
          }
          // Check if the desired duration and break fits the chosen time span.
        } else if (
          !this.editMode &&
          this.timeSpan < this.duration + this.slotBreak
        ) {
          const timeSpanTooNarrowError = this.$t(
            "createTimeSlotsModal.validationErrorTimeSpanTooNarrow"
          );
          if (!this.validationErrors.includes(timeSpanTooNarrowError)) {
            this.validationErrors.push(timeSpanTooNarrowError);
          }
        }
      }

      if (!this.validationErrors.length) {
        if (this.editMode) {
          this.updateTimeSlot();
        } else {
          this.createTimeSlots();
        }
      }
    },
    validTimestamp(timestamp) {
      var regex = /^\d{4}-\d\d-\d\dT\d\d:\d\d(:\d\d)?(([+-]\d\d:\d\d)|Z)?$/i;
      return regex.test(timestamp);
    },
    createTimeSlots() {
      this.timeSlotCreationInProgress = true;

      let startTimestamp = new Date(this.startTimestamp);
      let endTimestamp = new Date(this.endTimestamp);
      var timeSlots = [];

      if (endTimestamp > startTimestamp) {
        var tempStartTimestamp = startTimestamp;

        while (
          tempStartTimestamp <=
          new Date(
            endTimestamp.getTime() -
              this.duration * 60 * 1000 -
              this.slotBreak * 60 * 1000
          )
        ) {
          let endTempTimestamp = new Date(
            tempStartTimestamp.getTime() + this.duration * 60 * 1000
          );

          let timeSlot = {
            date: this.date,
            event: { link: this.event._id },
            location: { link: this.selectLocation },
            startTimestamp: tempStartTimestamp,
            endTimestamp: endTempTimestamp,
            maxParticipants: this.maxParticipants,
          };

          timeSlots.push(timeSlot);

          tempStartTimestamp = new Date(
            tempStartTimestamp.getTime() +
              this.duration * 60 * 1000 +
              this.slotBreak * 60 * 1000
          );
        }

        this.$apollo
          .mutate({
            mutation: CreateTimeSlotsMutation,
            variables: {
              data: timeSlots,
            },
            update: (store, { data: { insertManyTimeSlots } }) => {
              const data = store.readQuery({
                query: TimeSlotsQuery,
                variables: {
                  query: {
                    event: { key: this.$route.params.eventKey },
                    date: this.date,
                  },
                },
              });
              const deepTimeSlots = cloneDeep(data.timeSlots);
              const newTimeSlots = timeSlots;
              for (var index = 0; index < timeSlots.length; index++) {
                let newTimeSlot = newTimeSlots[index];
                newTimeSlot.__typename = "TimeSlot";
                newTimeSlot._id = insertManyTimeSlots.insertedIds[index];
                newTimeSlot.event = { _id: this.$route.params.eventID };
                newTimeSlot.location = { _id: this.selectLocation };
                newTimeSlot.date = newTimeSlot.date.toISOString();
                newTimeSlot.startTimestamp =
                  newTimeSlot.startTimestamp.toISOString();
                newTimeSlot.endTimestamp =
                  newTimeSlot.endTimestamp.toISOString();
                newTimeSlot.availableParticipants = newTimeSlot.maxParticipants;
              }
              deepTimeSlots.push(...newTimeSlots);

              store.writeQuery({
                query: TimeSlotsQuery,
                variables: {
                  query: {
                    event: { key: this.$route.params.eventKey },
                    date: this.date,
                  },
                },
                data: { timeSlots: deepTimeSlots },
              });
            },
          })
          .then(() => {
            this.createTimeSlotsModalVisible = false;
            this.timeSlotCreationInProgress = false;
          })
          .catch((error) => {
            this.createTimeSlotsMutationError = error;
            this.timeSlotCreationInProgress = false;
          });
      }
    },
    updateTimeSlot() {
      this.timeSlotCreationInProgress = true;

      this.$apollo
        .mutate({
          mutation: UpdateTimeSlotMutation,
          variables: {
            set: { maxParticipants: this.maxParticipants },
            query: { _id: this.timeSlot._id },
          },
        })
        .then(() => {
          this.createTimeSlotsModalVisible = false;
          this.timeSlotCreationInProgress = false;
        })
        .catch((error) => {
          this.updateTimeSlotMutationError = error;
          this.timeSlotCreationInProgress = false;
        });
    },
  },
  apollo: {
    locations: {
      query: LocationsQuery,
      variables() {
        return {
          query: {
            event: {
              key: this.$route.params.eventKey
                ? this.$route.params.eventKey
                : this.event.key,
            },
          },
        };
      },
      skip() {
        return !this.$route.params.eventKey && !this.event;
      },
      error(error) {
        this.locationsQueryError = error;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.locations-empty {
  padding: @size4 @size12;
}
</style>
