var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'time-slot-row': true,
    highlighted: _vm.timeSlot._id === _vm.selectedTimeSlotID,
  }},[_c('h5',[_vm._v(" "+_vm._s(new Date(_vm.timeSlot.startTimestamp).toLocaleTimeString(_vm.$i18n.locale, { hour: "2-digit", minute: "2-digit", }))+" - "+_vm._s(new Date(_vm.timeSlot.endTimestamp).toLocaleTimeString(_vm.$i18n.locale, { hour: "2-digit", minute: "2-digit", }))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$tc( "timeSlotRow.availableParticipants", _vm.timeSlot.availableParticipants, { availableParticipants: _vm.timeSlot.availableParticipants, } ))+" ")]),(_vm.user && (_vm.user.role === 'admin' || _vm.user.role === 'agent'))?_c('p',{staticClass:"time-slot-row-meta"},[_vm._v(" "+_vm._s(_vm.$t("timeSlotRow.maxParticipants", { maxParticipants: _vm.timeSlot.maxParticipants, }))+" ")]):_vm._e(),(
      _vm.user &&
      (_vm.user.role === 'admin' || _vm.user.role === 'agent') &&
      _vm.reservations.length
    )?_c('div',[_c('hr',{staticClass:"time-slot-row-divider"}),_c('b',[_vm._v(_vm._s(_vm.$t("timeSlotRow.titleReservations")))]),_vm._l((_vm.reservations),function(reservation){return _c('ReservationRow',{key:reservation._id,attrs:{"reservation":reservation}})})],2):_vm._e(),_c('div',{staticClass:"time-slot-row-actions"},[_c('ButtonGroup',[_c('Button',{attrs:{"appearance":"primary","disabled":!_vm.enoughSlotsAvailable || !_vm.timeSlotIsInTheFuture},on:{"click":function($event){return _vm.createReservation()}}},[_vm._v(" "+_vm._s(_vm.$t("shared.buttonBook"))+" ")]),(
          _vm.user &&
          (_vm.user.role === 'admin' || _vm.user.role === 'agent') &&
          _vm.$route.params.eventKey
        )?_c('DropdownMenu',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
        var triggerID = ref.triggerID;
        var toggleOpen = ref.toggleOpen;
        var selected = ref.selected;
return [_c('Button',{staticClass:"button-actions",attrs:{"id":triggerID,"selected":selected},on:{"click":toggleOpen}},[_vm._v(" ... ")])]}}],null,false,784361453)},[_c('DropdownItemGroup',{attrs:{"title":_vm.$t('shared.dropdownGroupAdministration')}},[_c('DropdownItem',{on:{"click":function($event){_vm.createTimeSlotsModalVisible = true}}},[_vm._v(" "+_vm._s(_vm.$t("shared.dropdownItemEdit"))+" ")]),_c('DropdownItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.user && _vm.user.role === 'admin'),expression:"user && user.role === 'admin'"}],on:{"click":function($event){_vm.deleteTimeSlotModalVisible = true}}},[_vm._v(" "+_vm._s(_vm.$t("shared.dropdownItemDelete"))+" ")])],1)],1):_vm._e()],1)],1),(
      _vm.user &&
      (_vm.user.role === 'admin' || _vm.user.role === 'agent') &&
      _vm.$route.params.eventKey
    )?_c('CreateTimeSlotsModal',{attrs:{"edit-mode":true,"time-slot":_vm.timeSlot,"date":new Date(_vm.timeSlot.date)},model:{value:(_vm.createTimeSlotsModalVisible),callback:function ($$v) {_vm.createTimeSlotsModalVisible=$$v},expression:"createTimeSlotsModalVisible"}}):_vm._e(),_c('Modal',{attrs:{"close-on-overlay-click":!_vm.timeSlotDeletionInProgress},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('ModalHeader',{attrs:{"title":_vm.$t('timeSlotRow.deleteTimeSlotModalTitle')}})]},proxy:true},{key:"footer",fn:function(){return [_c('ModalFooter',[_c('ButtonGroup',[_c('Button',{attrs:{"disabled":_vm.timeSlotDeletionInProgress,"appearance":"danger"},on:{"click":function($event){return _vm.deleteTimeSlot()}}},[_vm._v(" "+_vm._s(_vm.$t("shared.dropdownItemDelete"))+" ")]),_c('Button',{attrs:{"disabled":_vm.timeSlotDeletionInProgress,"appearance":"subtle"},on:{"click":function($event){_vm.deleteTimeSlotModalVisible = false}}},[_vm._v(" "+_vm._s(_vm.$t("shared.buttonCancel"))+" ")])],1)],1)]},proxy:true}]),model:{value:(_vm.deleteTimeSlotModalVisible),callback:function ($$v) {_vm.deleteTimeSlotModalVisible=$$v},expression:"deleteTimeSlotModalVisible"}},[_c('p',[_vm._v(_vm._s(_vm.$t("timeSlotRow.deleteTimeSlotModalDescription")))]),(_vm.deleteTimeSlotMutationError)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.deleteTimeSlotMutationError.message)+" ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }