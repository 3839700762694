<template>
  <div class="reservation-row">
    <b>
      {{ reservation.participant.firstname }}
      {{ reservation.participant.lastname }}
    </b>
    <p class="reservation-row-company">{{ reservation.participant.company }}</p>
    <p
      v-if="reservation.accompanyingParticipants.length"
      class="reservation-row-accompanying-title"
    >
      {{
        $tc(
          "reservationRow.participants",
          reservation.accompanyingParticipants.length
        )
      }}
    </p>
    <ul>
      <li
        v-for="accompanyingParticipant in reservation.accompanyingParticipants"
        :key="accompanyingParticipant._id"
      >
        <p>
          {{ accompanyingParticipant.firstname }}
          {{ accompanyingParticipant.lastname }}
        </p>
      </li>
    </ul>
    <!-- <img
      v-if="reservation._editQRCode"
      class="reservation-image-qr"
      :src="reservation._editQRCode"
    /> -->
    <p v-if="resendConfirmationEmailResult">
      {{ resendConfirmationEmailResult }}
    </p>
    <div v-if="$route.params.eventKey" class="reservation-row-actions">
      <ButtonGroup>
        <DropdownMenu>
          <template #trigger="{ triggerID, toggleOpen, selected }">
            <Button :id="triggerID" :selected="selected" @click="toggleOpen">
              ...
            </Button>
          </template>
          <DropdownItemGroup :title="$t('shared.dropdownGroupAdministration')">
            <DropdownItem @click="editReservation()">
              {{ $t("shared.dropdownItemEdit") }}
            </DropdownItem>
            <DropdownItem @click="resendConfirmationEmail()">
              {{ $t("reservationRow.dropdownItemResendEmail") }}
            </DropdownItem>
            <DropdownItem @click="cancelReservationModalVisible = true">
              {{ $t("reservationRow.dropdownItemCancel") }}
            </DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      </ButtonGroup>
    </div>
    <Modal
      v-model="cancelReservationModalVisible"
      :close-on-overlay-click="!reservationCancellationInProgress"
    >
      <template #header>
        <ModalHeader
          :title="$t('reservationRow.cancelReservationModalTitle')"
        />
      </template>
      <p>{{ $t("reservationRow.cancelReservationModalDescription") }}</p>
      <p v-if="cancelReservationMutationError" class="error">
        {{ cancelReservationMutationError.message }}
      </p>
      <template #footer>
        <ModalFooter>
          <ButtonGroup>
            <Button
              :disabled="reservationCancellationInProgress"
              appearance="danger"
              @click="cancelReservation()"
            >
              {{ $t("shared.dropdownItemDelete") }}
            </Button>
            <Button
              :disabled="reservationCancellationInProgress"
              appearance="subtle"
              @click="cancelReservationModalVisible = false"
            >
              {{ $t("shared.buttonCancel") }}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
const cloneDeep = require("lodash.clonedeep");
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from "@gospore/gospore-web-ui-library-dropdown-menu";
import Button, { ButtonGroup } from "@gospore/gospore-web-ui-library-button";
import Modal, {
  ModalHeader,
  ModalFooter,
} from "@gospore/gospore-web-ui-library-modal";

import CancelReservationMutation from "@/graphql/CancelReservationMutation.gql";
import ReservationsQuery from "@/graphql/ReservationsQuery.gql";
import TimeSlotsQuery from "@/graphql/TimeSlotsQuery.gql";

export default {
  name: "ReservationRow",
  components: {
    DropdownMenu,
    DropdownItemGroup,
    DropdownItem,
    Button,
    ButtonGroup,
    Modal,
    ModalHeader,
    ModalFooter,
  },
  props: {
    reservation: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      cancelReservationModalVisible: false,
      reservationCancellationInProgress: false,
      cancelReservationMutationError: null,
      resendConfirmationEmailResult: null,
    };
  },
  computed: {
    ...mapState(["realmApp"]),
  },
  methods: {
    editReservation() {
      this.$store.commit("setReservationToEdit", this.reservation);
      this.$store.commit(
        "setSelectedTimeSlotID",
        this.reservation.timeSlot._id
      );
    },
    cancelReservation() {
      this.reservationCancellationInProgress = true;

      this.$apollo
        .mutate({
          mutation: CancelReservationMutation,
          variables: {
            input: this.reservation._editCode,
          },
          update: (store) => {
            // Remove reservation from query.
            const reservationsData = store.readQuery({
              query: ReservationsQuery,
              variables: {
                eventKey: this.$route.params.eventKey,
                date: new Date(this.reservation.timeSlot.date),
              },
            });
            const reservationsDataClone = cloneDeep(reservationsData);
            const index = reservationsDataClone.reservations.findIndex(
              (reservation) => reservation._id === this.reservation._id
            );
            reservationsDataClone.reservations.splice(index, 1);
            store.writeQuery({
              query: ReservationsQuery,
              variables: {
                eventKey: this.$route.params.eventKey,
                date: new Date(this.reservation.timeSlot.date),
              },
              data: reservationsDataClone,
            });

            // Update available participants for time slot.
            const timeSlotsData = store.readQuery({
              query: TimeSlotsQuery,
              variables: {
                query: {
                  event: {
                    key: this.$route.params.eventKey
                      ? this.$route.params.eventKey
                      : this.event.key,
                  },
                  date: this.date,
                },
              },
            });
            const timeSlotsDataClone = cloneDeep(timeSlotsData);
            const timeSlotIndex = timeSlotsDataClone.timeSlots.findIndex(
              (timeSlot) => timeSlot._id === this.reservation.timeSlot._id
            );
            const originalAvailableParticipants =
              timeSlotsDataClone.timeSlots[timeSlotIndex].availableParticipants;
            const modifiedAvailableParticipants =
              originalAvailableParticipants +
              (this.reservation.accompanyingParticipants.length + 1);
            timeSlotsDataClone.timeSlots[timeSlotIndex].availableParticipants =
              modifiedAvailableParticipants;
            store.writeQuery({
              query: TimeSlotsQuery,
              variables: {
                query: {
                  event: {
                    key: this.$route.params.eventKey
                      ? this.$route.params.eventKey
                      : this.event.key,
                  },
                  date: this.date,
                },
              },
              data: timeSlotsDataClone,
            });
          },
        })
        .then(() => {
          this.cancelReservationModalVisible = false;
          this.reservationCancellationInProgress = false;
        })
        .catch((error) => {
          this.cancelReservationMutationError = error;
          this.reservationCancellationInProgress = false;
        });
    },
    async resendConfirmationEmail() {
      console.log(this.realmApp.currentUser);
      const resendConfirmationEmailResult =
        await this.realmApp.currentUser.functions.resendConfirmationEmail(
          this.reservation._editCode
        );
      this.resendConfirmationEmailResult = resendConfirmationEmailResult;
    },
  },
};
</script>

<style lang="less" scoped>
.reservation-row {
  position: relative;
  padding: @size8 10px;
  margin-top: @size16;
  border: @borderWidth solid darken(@go-color-n50, 0%);
  border-radius: @borderRadius;
}
.reservation-row-company {
  margin-top: 0px;
}
.reservation-row-accompanying-title {
  margin-top: @size4;
}
.reservation-row ul {
  margin-top: @size4;
}
.reservation-image-qr {
  width: 50%;
}
.reservation-row-actions {
  margin-top: @size16;
}
</style>
