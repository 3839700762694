<template>
  <Grid>
    <GridColumn :columns="12">
      <h3 v-if="!editMode && !cancelMode">
        {{ $t("reservationSuccessfulView.title") }}
      </h3>
      <h3 v-if="editMode">{{ $t("reservationSuccessfulView.titleEdit") }}</h3>
      <h3 v-if="cancelMode">
        {{ $t("reservationSuccessfulView.titleCancel") }}
      </h3>
      <p v-if="!editMode && !cancelMode">
        {{ $t("reservationSuccessfulView.description") }}
      </p>
      <p v-if="editMode">
        {{ $t("reservationSuccessfulView.descriptionEdit") }}
      </p>
      <p v-if="cancelMode">
        {{ $t("reservationSuccessfulView.descriptionCancel") }}
      </p>
      <p v-if="!editMode && !cancelMode">
        {{ $t("reservationSuccessfulView.descriptionCalendarEntry") }}
      </p>
      <p v-if="editMode">
        {{ $t("reservationSuccessfulView.descriptionCalendarEntryEdit") }}
      </p>
      <ButtonGroup class="actions">
        <Button
          v-if="icalendar !== null"
          appearance="primary"
          @click="downloadIcalendar"
        >
          {{ $t("reservationSuccessfulView.buttonAddCalendarEntry") }}
        </Button>
        <Button @click="done()">{{ $t("shared.buttonOK") }}</Button>
      </ButtonGroup>
    </GridColumn>
  </Grid>
</template>

<script>
import { Grid, GridColumn } from "@gospore/gospore-web-ui-library-layout";
import Button, { ButtonGroup } from "@gospore/gospore-web-ui-library-button";

export default {
  name: "ReservationSuccessfulView",
  components: {
    Grid,
    GridColumn,
    Button,
    ButtonGroup,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    cancelMode: {
      type: Boolean,
      default: false,
    },
    icalendar: {
      type: Object,
      default: null,
    },
  },
  methods: {
    downloadIcalendar() {
      if (this.icalendar !== null) {
        this.icalendar.download();
      }
    },
    done() {
      this.$emit("done");
    },
  },
};
</script>

<style lang="less" scoped>
.actions {
  margin-top: @size24;
}
</style>
