<template>
  <Modal
    v-model="createEventModalVisible"
    :close-on-overlay-click="!eventCreationInProgress"
    :allow-outside-click="true"
    :width="765"
  >
    <Form @submit="validateForm()">
      <FormHeader
        :title="
          !editMode
            ? $t('createEventModal.titleCreate')
            : $t('createEventModal.titleEdit')
        "
      />
      <TranslationFormSection
        id="name"
        v-model="name"
        :description="
          !editMode
            ? $t('createEventModal.descriptionCreate')
            : $t('createEventModal.descriptionEdit')
        "
        :placeholder="$t('createEventModal.textfieldPlaceholderName')"
        :label="$t('createEventModal.textfieldLabelName')"
      />
      <TranslationFormSection
        id="description"
        v-model="description"
        :description="$t('createEventModal.descriptionDescription')"
        :placeholder="$t('createEventModal.textfieldPlaceholderDescription')"
        :label="$t('createEventModal.textfieldLabelDescription')"
      />
      <TranslationFormSection
        id="company"
        v-model="company"
        :title="$t('createEventModal.titleEmailTemplateSection')"
        :description="$t('createEventModal.descriptionCompany')"
        :placeholder="$t('createEventModal.textfieldPlaceholderCompany')"
        :label="$t('createEventModal.textfieldLabelCompany')"
      />
      <TranslationFormSection
        id="phoneNumber"
        v-model="phoneNumber"
        :placeholder="$t('createEventModal.textfieldPlaceholderPhoneNumber')"
        :label="$t('createEventModal.textfieldLabelPhoneNumber')"
      />
      <TranslationFormSection
        id="emailAddress"
        v-model="emailAddress"
        :placeholder="$t('createEventModal.textfieldPlaceholderEmailAddress')"
        :label="$t('createEventModal.textfieldLabelEmailAddress')"
      />
      <TranslationFormSection
        id="companyAddress"
        v-model="companyAddress"
        :placeholder="$t('createEventModal.textfieldPlaceholderCompanyAddress')"
        :label="$t('createEventModal.textfieldLabelCompanyAddress')"
      />
      <TranslationFormSection
        id="displayUrl"
        v-model="displayUrl"
        :placeholder="$t('createEventModal.textfieldPlaceholderDisplayUrl')"
        :label="$t('createEventModal.textfieldLabelDisplayUrl')"
      />
      <TranslationFormSection
        id="websiteUrl"
        v-model="websiteUrl"
        :placeholder="$t('createEventModal.textfieldPlaceholderWebsiteUrl')"
        :label="$t('createEventModal.textfieldLabelWebsiteUrl')"
      />
      <FormSection
        v-if="
          validationErrors.length ||
          createEventMutationError ||
          updateEventMutationError
        "
      >
        <div v-if="validationErrors.length">
          <b v-t="'shared.validationErrorDescription'" />
          <ul>
            <li
              v-for="validationError in validationErrors"
              :key="validationError"
            >
              <p>{{ validationError }}</p>
            </li>
          </ul>
        </div>
        <p v-if="createEventMutationError" class="error">
          {{ createEventMutationError.message }}
        </p>
        <p v-if="updateEventMutationError" class="error">
          {{ updateEventMutationError.message }}
        </p>
      </FormSection>
      <FormFooter>
        <ButtonGroup>
          <Button
            :disabled="eventCreationInProgress"
            type="submit"
            appearance="primary"
          >
            {{
              !editMode ? $t("shared.buttonCreate") : $t("shared.buttonSave")
            }}
          </Button>
          <Button
            :disabled="eventCreationInProgress"
            appearance="subtle"
            @click="createEventModalVisible = false"
          >
            {{ $t("shared.buttonCancel") }}
          </Button>
        </ButtonGroup>
      </FormFooter>
    </Form>
  </Modal>
</template>

<script>
const cloneDeep = require("lodash.clonedeep");
import Modal from "@gospore/gospore-web-ui-library-modal";
import Form, {
  FormHeader,
  FormSection,
  FormFooter,
} from "@gospore/gospore-web-ui-library-form";
import Button, { ButtonGroup } from "@gospore/gospore-web-ui-library-button";
import TranslationFormSection from "@/components/TranslationFormSection.vue";

import CreateEventMutation from "@/graphql/CreateEventMutation.gql";
import EventsQuery from "@/graphql/EventsQuery.gql";
import UpdateEventMutation from "@/graphql/UpdateEventMutation.gql";

export default {
  name: "CreateEventModal",
  components: {
    Modal,
    Form,
    FormHeader,
    FormSection,
    FormFooter,
    Button,
    ButtonGroup,
    TranslationFormSection,
  },
  model: {
    prop: "visible",
    event: "visible-changed",
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      name: this.event ? JSON.parse(this.event.name) : null,
      description: this.event ? JSON.parse(this.event.description) : null,
      company: this.event ? JSON.parse(this.event.company) : null,
      phoneNumber: this.event ? JSON.parse(this.event.phoneNumber) : null,
      emailAddress: this.event ? JSON.parse(this.event.emailAddress) : null,
      companyAddress: this.event ? JSON.parse(this.event.companyAddress) : null,
      displayUrl: this.event ? JSON.parse(this.event.displayUrl) : null,
      websiteUrl: this.event ? JSON.parse(this.event.websiteUrl) : null,
      createEventModalVisible: this.visible,
      validationErrors: [],
      createEventMutationError: null,
      updateEventMutationError: null,
      eventCreationInProgress: false,
    };
  },
  watch: {
    createEventModalVisible() {
      this.$emit("visible-changed", this.createEventModalVisible);
    },
    visible() {
      this.createEventModalVisible = this.visible;
    },
  },
  methods: {
    validateForm() {
      this.createEventMutationError = null;
      this.updateEventMutationError = null;
      this.validationErrors = [];

      if (
        !this.name ||
        !this.company ||
        !this.phoneNumber ||
        !this.emailAddress ||
        !this.companyAddress ||
        !this.displayUrl ||
        !this.websiteUrl
      ) {
        const missingNeccessaryFieldError = this.$t(
          "shared.validationErrorMissingNeccessaryField"
        );
        if (!this.validationErrors.includes(missingNeccessaryFieldError)) {
          this.validationErrors.push(missingNeccessaryFieldError);
        }
      } else {
        let needsToGetChecked = [
          this.name,
          this.company,
          this.phoneNumber,
          this.emailAddress,
          this.companyAddress,
          this.displayUrl,
          this.websiteUrl,
        ];
        for (const toBeChecked of needsToGetChecked) {
          for (const translation of toBeChecked) {
            if (
              !translation.locale ||
              !translation.text ||
              translation.locale === "" ||
              translation.text === "" ||
              (translation.locale === "" && translation.text === "")
            ) {
              const missingNeccessaryFieldError = this.$t(
                "shared.validationErrorMissingNeccessaryField"
              );
              if (
                !this.validationErrors.includes(missingNeccessaryFieldError)
              ) {
                this.validationErrors.push(missingNeccessaryFieldError);
              }
            }
          }
        }
      }

      if (!this.validationErrors.length) {
        if (this.editMode) {
          this.updateEvent();
        } else {
          this.createEvent();
        }
      }
    },
    createEvent() {
      this.eventCreationInProgress = true;

      let key = this.name
        .find((translation) => translation.locale === this.$i18n.fallbackLocale)
        .text.replace(/\s+/g, "-")
        .toLowerCase();

      this.$apollo
        .mutate({
          mutation: CreateEventMutation,
          variables: {
            data: {
              name: JSON.stringify(this.name),
              description: JSON.stringify(this.description),
              company: JSON.stringify(this.company),
              phoneNumber: JSON.stringify(this.phoneNumber),
              emailAddress: JSON.stringify(this.emailAddress),
              companyAddress: JSON.stringify(this.companyAddress),
              displayUrl: JSON.stringify(this.displayUrl),
              websiteUrl: JSON.stringify(this.websiteUrl),
              key,
            },
          },
          update: (store, { data: { insertOneEvent } }) => {
            const eventsData = store.readQuery({
              query: EventsQuery,
            });
            const eventsDataClone = cloneDeep(eventsData);
            eventsDataClone.events.push(insertOneEvent);
            store.writeQuery({
              query: EventsQuery,
              data: eventsDataClone,
            });
          },
        })
        .then(() => {
          this.createEventModalVisible = false;
          this.eventCreationInProgress = false;

          this.name = null;
          this.description = null;
          this.company = null;
          this.phoneNumber = null;
          this.emailAddress = null;
          this.companyAddress = null;
          this.displayUrl = null;
          this.websiteUrl = null;
          this.validationErrors = [];
          this.createEventMutationError = null;
          this.updateEventMutationError = null;
        })
        .catch((error) => {
          this.createEventMutationError = error;
          this.eventCreationInProgress = false;
        });
    },
    updateEvent() {
      this.eventCreationInProgress = true;

      this.$apollo
        .mutate({
          mutation: UpdateEventMutation,
          variables: {
            set: {
              name: JSON.stringify(this.name),
              description: JSON.stringify(this.description),
              company: JSON.stringify(this.company),
              phoneNumber: JSON.stringify(this.phoneNumber),
              emailAddress: JSON.stringify(this.emailAddress),
              companyAddress: JSON.stringify(this.companyAddress),
              displayUrl: JSON.stringify(this.displayUrl),
              websiteUrl: JSON.stringify(this.websiteUrl),
            },
            query: { _id: this.event._id },
          },
        })
        .then(() => {
          this.createEventModalVisible = false;
          this.eventCreationInProgress = false;

          this.validationErrors = [];
          this.createEventMutationError = null;
          this.updateEventMutationError = null;
        })
        .catch((error) => {
          this.updateEventMutationError = error;
          this.eventCreationInProgress = false;
        });
    },
  },
};
</script>
