import Vue from "vue";
import * as Realm from "realm-web";
import VueApollo from "vue-apollo";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import jwt_decode from "jwt-decode";
import store from "@/store";

Vue.use(VueApollo);

const APP_ID_PRODUCTION = "roadshow-jgvyv";
const APP_ID_TESTING = "roadshow-testing-phweg";
const API_KEY_PRODUCTION =
  "espa0kMtWhXeNsFIlrjpdwNLATw6qOTpPpKIxw4YE4iRrafTp2i521xSwOZwrrEH";
const API_KEY_TESTING =
  "ckuyZU4olcFzBMBc0VM8GnxsV574yDKFCYkyiwd0bDWp56Ts4GRk2wUTn6cQO4WJ";
const APP_ID =
  process.env.NODE_ENV === "production" ? APP_ID_PRODUCTION : APP_ID_TESTING;
const API_KEY =
  process.env.NODE_ENV === "production" ? API_KEY_PRODUCTION : API_KEY_TESTING;
const APP_URI = `https://realm.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`;

const app = new Realm.App(APP_ID);
store.commit("setRealmApp", app);

async function getValidAccessToken() {
  if (!app.currentUser) {
    await app.logIn(Realm.Credentials.apiKey(API_KEY));
  } else {
    await app.currentUser.refreshCustomData();
  }

  let decodedAccessToken = jwt_decode(app.currentUser.accessToken);
  store.commit("setUser", decodedAccessToken.user_data);

  return app.currentUser.accessToken;
}

async function login(key) {
  await app.logIn(Realm.Credentials.apiKey(key));

  clearCache();

  let decodedAccessToken = jwt_decode(app.currentUser.accessToken);
  store.commit("setUser", decodedAccessToken.user_data);
}

async function logout() {
  const user = app.currentUser;
  await app.removeUser(user);

  clearCache();

  let decodedAccessToken = jwt_decode(app.currentUser.accessToken);
  store.commit("setUser", decodedAccessToken.user_data);
}

function clearCache() {
  client.cache.data.clear();
}

const link = createHttpLink({
  uri: APP_URI,
  fetch: async (uri, options) => {
    const accessToken = await getValidAccessToken();
    options.headers.Authorization = `Bearer ${accessToken}`;
    return fetch(uri, options);
  },
});

const client = new ApolloClient({
  connectToDevTools: true,
  link,
  cache: new InMemoryCache({
    addTypename: true,
    typePolicies: {
      Query: {
        fields: {
          event(_, { args, toReference }) {
            return toReference({
              __typename: "Event",
              id: args.query._id,
            });
          },
          timeSlot(_, { args, toReference }) {
            return toReference({
              __typename: "TimeSlot",
              id: args.query._id,
            });
          },
        },
      },
    },
  }),
});

const apolloProvider = new VueApollo({
  defaultClient: client,
});

export default apolloProvider;
export { login, logout, clearCache };
