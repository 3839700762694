<template>
  <div
    :class="{
      'time-slot-row': true,
      highlighted: timeSlot._id === selectedTimeSlotID,
    }"
  >
    <h5>
      {{
        new Date(timeSlot.startTimestamp).toLocaleTimeString($i18n.locale, {
          hour: "2-digit",
          minute: "2-digit",
        })
      }}
      -
      {{
        new Date(timeSlot.endTimestamp).toLocaleTimeString($i18n.locale, {
          hour: "2-digit",
          minute: "2-digit",
        })
      }}
    </h5>
    <p>
      {{
        $tc(
          "timeSlotRow.availableParticipants",
          timeSlot.availableParticipants,
          {
            availableParticipants: timeSlot.availableParticipants,
          }
        )
      }}
    </p>
    <p
      v-if="user && (user.role === 'admin' || user.role === 'agent')"
      class="time-slot-row-meta"
    >
      {{
        $t("timeSlotRow.maxParticipants", {
          maxParticipants: timeSlot.maxParticipants,
        })
      }}
    </p>
    <div
      v-if="
        user &&
        (user.role === 'admin' || user.role === 'agent') &&
        reservations.length
      "
    >
      <hr class="time-slot-row-divider" />
      <b>{{ $t("timeSlotRow.titleReservations") }}</b>
      <ReservationRow
        v-for="reservation in reservations"
        :key="reservation._id"
        :reservation="reservation"
      />
    </div>
    <div class="time-slot-row-actions">
      <ButtonGroup>
        <Button
          appearance="primary"
          :disabled="!enoughSlotsAvailable || !timeSlotIsInTheFuture"
          @click="createReservation()"
        >
          {{ $t("shared.buttonBook") }}
        </Button>
        <DropdownMenu
          v-if="
            user &&
            (user.role === 'admin' || user.role === 'agent') &&
            $route.params.eventKey
          "
        >
          <template #trigger="{ triggerID, toggleOpen, selected }">
            <Button
              :id="triggerID"
              class="button-actions"
              :selected="selected"
              @click="toggleOpen"
            >
              ...
            </Button>
          </template>
          <DropdownItemGroup :title="$t('shared.dropdownGroupAdministration')">
            <DropdownItem @click="createTimeSlotsModalVisible = true">
              {{ $t("shared.dropdownItemEdit") }}
            </DropdownItem>
            <DropdownItem
              v-show="user && user.role === 'admin'"
              @click="deleteTimeSlotModalVisible = true"
            >
              {{ $t("shared.dropdownItemDelete") }}
            </DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      </ButtonGroup>
    </div>
    <CreateTimeSlotsModal
      v-if="
        user &&
        (user.role === 'admin' || user.role === 'agent') &&
        $route.params.eventKey
      "
      v-model="createTimeSlotsModalVisible"
      :edit-mode="true"
      :time-slot="timeSlot"
      :date="new Date(timeSlot.date)"
    />
    <Modal
      v-model="deleteTimeSlotModalVisible"
      :close-on-overlay-click="!timeSlotDeletionInProgress"
    >
      <template #header>
        <ModalHeader :title="$t('timeSlotRow.deleteTimeSlotModalTitle')" />
      </template>
      <p>{{ $t("timeSlotRow.deleteTimeSlotModalDescription") }}</p>
      <p v-if="deleteTimeSlotMutationError" class="error">
        {{ deleteTimeSlotMutationError.message }}
      </p>
      <template #footer>
        <ModalFooter>
          <ButtonGroup>
            <Button
              :disabled="timeSlotDeletionInProgress"
              appearance="danger"
              @click="deleteTimeSlot()"
            >
              {{ $t("shared.dropdownItemDelete") }}
            </Button>
            <Button
              :disabled="timeSlotDeletionInProgress"
              appearance="subtle"
              @click="deleteTimeSlotModalVisible = false"
            >
              {{ $t("shared.buttonCancel") }}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
const cloneDeep = require("lodash.clonedeep");
import Button, { ButtonGroup } from "@gospore/gospore-web-ui-library-button";
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from "@gospore/gospore-web-ui-library-dropdown-menu";
import Modal, {
  ModalHeader,
  ModalFooter,
} from "@gospore/gospore-web-ui-library-modal";
import ReservationRow from "@/components/ReservationRow.vue";
import CreateTimeSlotsModal from "@/views/CreateTimeSlotsModal.vue";

import DeleteTimeSlotMutation from "@/graphql/DeleteTimeSlotMutation.gql";
import TimeSlotsQuery from "@/graphql/TimeSlotsQuery.gql";

export default {
  name: "TimeSlotRow",
  components: {
    Button,
    ButtonGroup,
    DropdownMenu,
    DropdownItemGroup,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalFooter,
    ReservationRow,
    CreateTimeSlotsModal,
  },
  props: {
    timeSlot: {
      type: Object,
      default: null,
    },
    reservations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      createTimeSlotsModalVisible: false,
      deleteTimeSlotModalVisible: false,
      timeSlotDeletionInProgress: false,
      deleteTimeSlotMutationError: null,
    };
  },
  computed: {
    enoughSlotsAvailable() {
      if (this.reservationCurrentlyAccompanyingParticipants) {
        if (
          this.reservationToEdit &&
          this.timeSlot._id === this.reservationToEdit.timeSlot._id
        ) {
          return true;
        }
        return (
          this.timeSlot.availableParticipants >=
          this.reservationCurrentlyAccompanyingParticipants
        );
      } else {
        return this.timeSlot.availableParticipants > 0;
      }
    },
    timeSlotIsInTheFuture() {
      if (
        new Date(this.timeSlot.startTimestamp).getTime() > new Date().getTime()
      ) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState([
      "reservationCurrentlyAccompanyingParticipants",
      "selectedTimeSlotID",
      "reservationToEdit",
      "user",
    ]),
    ...mapGetters(["localizedText"]),
  },
  methods: {
    createReservation() {
      this.$store.commit("setSelectedTimeSlotID", this.timeSlot._id);

      this.$nextTick(() => {
        const createReservationView = document.getElementById(
          "createReservationView"
        );
        const yOffset = -32;
        const y =
          createReservationView.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      });
    },
    deleteTimeSlot() {
      this.timeSlotDeletionInProgress = true;

      this.$apollo
        .mutate({
          mutation: DeleteTimeSlotMutation,
          variables: {
            input: this.timeSlot._id,
          },
          update: (store) => {
            const timeSlotsData = store.readQuery({
              query: TimeSlotsQuery,
              variables: {
                query: {
                  event: { key: this.$route.params.eventKey },
                  date: new Date(this.timeSlot.date),
                },
              },
            });
            console.log(timeSlotsData);
            const timeSlotsDataClone = cloneDeep(timeSlotsData);
            const index = timeSlotsDataClone.timeSlots.findIndex(
              (timeSlot) => timeSlot._id === this.timeSlot._id
            );
            timeSlotsDataClone.timeSlots.splice(index, 1);
            store.writeQuery({
              query: TimeSlotsQuery,
              variables: {
                query: {
                  event: { key: this.$route.params.eventKey },
                  date: new Date(this.timeSlot.date),
                },
              },
              data: timeSlotsDataClone,
            });
          },
        })
        .then(() => {
          console.log("THENNNN");
          this.$apollo.queries.timeSlots.refetch({
            query: {
              event: {
                key: this.$route.params.eventKey,
              },
            },
          });
          this.deleteTimeSlotModalVisible = false;
          this.timeSlotDeletionInProgress = false;
        })
        .catch((error) => {
          this.deleteTimeSlotMutationError = error;
          this.timeSlotDeletionInProgress = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.time-slot-row {
  background-color: @go-color-n30;
  border-radius: @borderRadiusLarge;
  padding: @size24;
  transition: all 0.1s ease-out;
  box-shadow: #elevation.card[light];
}
.time-slot-row:hover {
  background-color: @go-color-n40;
}
.time-slot-row-divider {
  margin-top: @size16;
  margin-bottom: @size16;
}
.time-slot-row-meta {
  margin-top: @size0;
}
.highlighted {
  background-color: fade(@color-primary, 100%);
  color: @go-color-n0;
}
.highlighted:hover {
  background-color: fade(@color-primary, 90%);
  color: @go-color-n0;
}
.highlighted h5 {
  color: @go-color-n0 !important;
}
.time-slot-row-actions {
  margin-top: @size16;
}
.button-actions {
  background-color: @go-color-n20 !important;

  &:hover {
    background-color: @go-color-n30 !important;
  }
  &:active {
    background-color: fade(@go-color-b75, 60%) !important;
  }
  &.button-appearance-selected {
    background-color: darken(@color-secondary-shaded-blue, 10%) !important;

    &:hover {
      background-color: darken(@color-secondary-shaded-blue, 15%) !important;
    }
    &:active {
      background-color: lighten(@color-secondary-shaded-blue, 15%) !important;
    }
  }
}
</style>
