<template>
  <Grid>
    <GridColumn :columns="12">
      <h1>{{ $t("eventListView.title") }}</h1>
      <p>{{ $t("eventListView.description") }}</p>
      <div v-if="!loading">
        <div v-if="!eventsQueryError" class="event-list">
          <div v-if="events.length" class="event-list-grid">
            <EventRow v-for="event in events" :key="event._id" :event="event" />
          </div>
          <p v-else>{{ $t("eventListView.listEmpty") }}</p>
        </div>
        <p v-else class="error">
          {{ eventsQueryError.message }}
        </p>
        <div v-if="user && user.role === 'admin'" class="event-list-actions">
          <Button @click="createEventModalVisible = true">
            {{ $t("eventListView.buttonCreate") }}
          </Button>
        </div>
      </div>
      <p v-else class="event-list">Loading..</p>
    </GridColumn>
    <CreateEventModal
      v-if="user && user.role === 'admin'"
      v-model="createEventModalVisible"
    />
  </Grid>
</template>

<script>
import { mapState } from "vuex";
import { Grid, GridColumn } from "@gospore/gospore-web-ui-library-layout";
import Button from "@gospore/gospore-web-ui-library-button";
import EventRow from "@/components/EventRow.vue";
import CreateEventModal from "@/views/CreateEventModal.vue";

import EventsQuery from "@/graphql/EventsQuery.gql";

export default {
  name: "EventListView",
  components: {
    Grid,
    GridColumn,
    Button,
    EventRow,
    CreateEventModal,
  },
  data() {
    return {
      events: [],
      createEventModalVisible: false,
      eventsQueryError: null,
    };
  },
  computed: {
    loading() {
      return this.$apollo.queries.events.loading;
    },
    ...mapState(["user"]),
  },
  apollo: {
    events: {
      query: EventsQuery,
      error(error) {
        this.eventsQueryError = error;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.event-list {
  margin-top: @size32;
}
.event-list-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: @size16;
}
@media only screen and (max-width: 720px) {
  .event-list-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.event-list-actions {
  margin-top: @size24;
}
</style>
