<template>
  <div class="time-slot-list">
    <h3 class="time-slot-list-title">
      {{
        $t(
          timeSlots.length ? "timeSlotList.title[1]" : "timeSlotList.title[0]",
          {
            date: date.toLocaleDateString($i18n.locale, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            }),
            location: locationName,
          }
        )
      }}
    </h3>
    <div v-if="!loading">
      <div v-if="!timeSlotsQueryError && !reservationsQueryError">
        <div v-if="timeSlotsForDate.length > 0" class="time-slot-list-grid">
          <TimeSlotRow
            v-for="timeSlot in timeSlotsForDate"
            :key="timeSlot._id"
            :time-slot="timeSlot"
            :reservations="reservationsForTimeSlot(timeSlot._id)"
          />
        </div>
        <p v-else>{{ $t("timeSlotList.listEmpty") }}</p>
      </div>
      <div v-else>
        <p v-if="timeSlotsQueryError" class="error">
          {{ timeSlotsQueryError.message }}
        </p>
        <p v-if="reservationsQueryError" class="error">
          {{ reservationsQueryError.message }}
        </p>
      </div>
    </div>
    <p v-else>Loading..</p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import TimeSlotRow from "@/components/TimeSlotRow.vue";

import TimeSlotsQuery from "@/graphql/TimeSlotsQuery.gql";
import ReservationsQuery from "@/graphql/ReservationsQuery.gql";

export default {
  name: "TimeSlotList",
  components: {
    TimeSlotRow,
  },
  props: {
    date: {
      type: Date,
      default: new Date("2021-07-13"),
    },
  },
  data() {
    return {
      timeSlots: [],
      reservations: [],
      timeSlotsQueryError: null,
      reservationsQueryError: null,
    };
  },
  computed: {
    timeSlotsForDate() {
      if (this.timeSlots) {
        return this.timeSlots.filter(
          (timeSlot) =>
            new Date(timeSlot.date).getTime() === this.date.getTime()
        );
      } else {
        return [];
      }
    },
    loading() {
      return (
        this.$apollo.queries.timeSlots.loading ||
        this.$apollo.queries.reservations.loading
      );
    },
    locationName() {
      if (this.timeSlots.length) {
        return this.localizedText(this.timeSlots[0].location.name);
      } else {
        return "";
      }
    },
    ...mapState(["event", "user"]),
    ...mapGetters(["localizedText"]),
  },
  watch: {
    date(newDate, oldDate) {
      if (newDate.getTime() !== oldDate.getTime()) {
        this.timeSlots = [];
        this.reservations = [];
      }
    },
  },
  methods: {
    reservationsForTimeSlot(timeSlotID) {
      return this.reservations.filter(
        (reservation) => reservation.timeSlot._id === timeSlotID
      );
    },
  },
  apollo: {
    timeSlots: {
      query: TimeSlotsQuery,
      variables() {
        return {
          query: {
            event: {
              key: this.$route.params.eventKey
                ? this.$route.params.eventKey
                : this.event.key,
            },
            date: this.date,
          },
        };
      },
      skip() {
        return !this.$route.params.eventKey && !this.event;
      },
      error(error) {
        this.timeSlotsQueryError = error;
      },
    },
    reservations: {
      query: ReservationsQuery,
      variables() {
        return {
          eventKey: this.$route.params.eventKey
            ? this.$route.params.eventKey
            : this.event.key,
          date: this.date,
        };
      },
      skip() {
        return (
          !this.$route.params.eventKey &&
          !this.event &&
          !this.user &&
          (this.user.role !== "agent" || this.user.role !== "admin")
        );
      },
      error(error) {
        this.reservationsQueryError = error;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.time-slot-list {
  margin-top: @size64;
}
.time-slot-list-title {
  margin-bottom: @size32;
}
.time-slot-list-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: @size16;
}
@media only screen and (max-width: 920px) {
  .time-slot-list-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 720px) {
  .time-slot-list-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 600px) {
  .time-slot-list-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
