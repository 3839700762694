<template>
  <div>
    <template v-if="!loading">
      <template
        v-if="
          !timeSlotsQueryError &&
          !eventByIDQueryError &&
          !reservationByIDQueryError
        "
      >
        <template v-if="event">
          <Grid>
            <GridColumn :columns="12">
              <div class="event-view-actions">
                <h1 class="event-view-title">{{ $t("eventView.title") }}</h1>
                <div
                  v-if="user && user.role === 'admin' && $route.params.eventKey"
                  class="event-view-action"
                >
                  <Button
                    :disabled="exportInProgress"
                    @click="exportReservations()"
                  >
                    {{ $t("eventView.buttonExportReservations") }}
                  </Button>
                </div>
              </div>
              <h2 class="event-view-name">{{ localizedText(event.name) }}</h2>
              <p>{{ localizedText(event.description) }}</p>
              <LocationList />
            </GridColumn>
            <CreateTimeSlotsModal
              v-model="createTimeSlotsModalVisible"
              :date="selectedDate"
            />
          </Grid>
          <div id="booking" class="section-booking">
            <Grid v-if="!showReservationSuccessfulView.show">
              <GridColumn :columns="12">
                <h2>
                  {{
                    reservationToEdit
                      ? $t("createReservationView.titleEdit")
                      : $t("createReservationView.titleBook")
                  }}
                </h2>
                <div v-if="reservation" class="qr-code">
                  <div class="qr-code-column">
                    <img
                      v-if="reservation._editQRCode"
                      class="qr-code-column-image"
                      :src="reservation._editQRCode"
                    />
                  </div>
                  <div class="qr-code-column">
                    <h4>{{ $t("eventView.qrTitle") }}</h4>
                    <p>{{ $t("eventView.qrDescription") }}</p>
                  </div>
                </div>
                <BookingCalendar
                  v-model="selectedDate"
                  class="booking-calendar"
                  :highlighted-dates="highlightedDates"
                  :colored-highlighted-dates="coloredHighlightedDates"
                />
                <TimeSlotList :date="selectedDate" />
                <Button
                  v-if="user && user.role === 'admin' && $route.params.eventKey"
                  style="margin-top: 32px"
                  @click="createTimeSlotsModalVisible = true"
                >
                  {{ $t("eventView.buttonCreateTimeSlots") }}
                </Button>
              </GridColumn>
              <GridColumn
                v-if="selectedTimeSlotID || showReservationSuccessfulView.show"
                :columns="12"
              >
                <hr />
                <CreateReservationView
                  id="createReservationView"
                  @reservation="reservationSuccessful"
                />
              </GridColumn>
            </Grid>
            <ReservationSuccessfulView
              v-else
              :icalendar="icalendar"
              :edit-mode="showReservationSuccessfulView.editMode"
              :cancel-mode="showReservationSuccessfulView.cancelMode"
              @done="showReservationSuccessfulView.show = false"
            />
          </div>
        </template>
        <Grid v-else>
          <GridColumn :columns="12">
            <h1>{{ $t("eventView.title") }}</h1>
            <p v-if="$route.params.eventKey">
              {{ $t("eventView.eventNotFound") }}
            </p>
            <p v-else>
              {{ $t("eventView.reservationNotFound") }}
            </p>
          </GridColumn>
        </Grid>
      </template>
      <Grid v-else>
        <GridColumn class="error" :columns="12">
          <h1>{{ $t("eventView.title") }}</h1>
          <p v-if="timeSlotsQueryError">{{ timeSlotsQueryError.message }}</p>
          <p v-if="eventByIDQueryError">{{ eventByIDQueryError.message }}</p>
          <p v-if="reservationByIDQueryError">
            {{ reservationByIDQueryError.message }}
          </p>
        </GridColumn>
      </Grid>
    </template>
    <Grid v-else>
      <GridColumn :columns="12">
        <h1>{{ $t("eventView.title") }}</h1>
        <p>Loading..</p>
      </GridColumn>
    </Grid>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { Grid, GridColumn } from "@gospore/gospore-web-ui-library-layout";
import Button from "@gospore/gospore-web-ui-library-button";
import LocationList from "@/components/LocationList.vue";
import BookingCalendar from "@/components/BookingCalendar.vue";
import TimeSlotList from "@/components/TimeSlotList.vue";
import CreateReservationView from "@/views/CreateReservationView.vue";
import CreateTimeSlotsModal from "@/views/CreateTimeSlotsModal.vue";
import ReservationSuccessfulView from "@/views/ReservationSuccessfulView.vue";

import TimeSlotsQuery from "@/graphql/TimeSlotsQuery.gql";
import EventByIDQuery from "@/graphql/EventByIDQuery.gql";
import ReservationByIDQuery from "@/graphql/ReservationByIDQuery.gql";

export default {
  name: "EventView",
  components: {
    Grid,
    GridColumn,
    Button,
    LocationList,
    BookingCalendar,
    TimeSlotList,
    CreateReservationView,
    CreateTimeSlotsModal,
    ReservationSuccessfulView,
  },
  data() {
    return {
      selectedDate: new Date(
        Date.UTC(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      ),
      timeSlots: [],
      event: null,
      reservation: null,
      createTimeSlotsModalVisible: false,
      showReservationSuccessfulView: {
        show: false,
        editMode: false,
        cancelMode: false,
      },
      timeSlotsQueryError: null,
      eventByIDQueryError: null,
      reservationByIDQueryError: null,
      icalendar: null,
      exportInProgress: false,
    };
  },
  computed: {
    loading() {
      return (
        this.$apollo.queries.reservation.loading ||
        this.$apollo.queries.event.loading
      );
    },
    highlightedDates() {
      return this.timeSlots.map((timeSlot) => new Date(timeSlot.date));
    },
    coloredHighlightedDates() {
      if (this.selectedLocation) {
        return this.timeSlots
          .filter(
            (timeSlot) => timeSlot.location._id === this.selectedLocation._id
          )
          .map((timeSlot) => new Date(timeSlot.date));
      } else {
        return [];
      }
    },
    ...mapState([
      "realmApp",
      "selectedLocation",
      "selectedTimeSlotID",
      "reservationToEdit",
      "user",
    ]),
    ...mapGetters(["localizedText"]),
  },
  watch: {
    event() {
      this.$store.commit("setEvent", this.event);
    },
    selectedDate: {
      immediate: true,
      handler(value) {
        const locations = this.timeSlots
          .filter(
            (timeSlot) => new Date(timeSlot.date).getTime() === value.getTime()
          )
          .map((timeSlot) => timeSlot.location);
        const location = locations[0];
        if (location) {
          this.$store.commit("setSelectedLocation", location);
        } else {
          this.$store.commit("setSelectedLocation", null);
        }
      },
    },
    createTimeSlotsModalVisible() {
      if (!this.createTimeSlotsModalVisible && !this.$route.params.editCode) {
        this.$apollo.queries.timeSlots.refetch({
          query: {
            event: {
              key: this.$route.params.eventKey,
            },
          },
          sortBy: "STARTTIMESTAMP_ASC",
          limit: 1000,
        });
      }
    },
  },
  methods: {
    reservationSuccessful(payload) {
      this.showReservationSuccessfulView = {
        show: true,
        editMode: payload.editMode,
        cancelMode: payload.cancelMode,
      };
      this.icalendar = payload.calendar;
    },
    async exportReservations() {
      this.exportInProgress = true;

      let resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
      let timeZone = {
        locale: resolvedOptions.locale,
        timeZone: resolvedOptions.timeZone,
        offset: new Date().getTimezoneOffset(),
      };
      const exportReservationsResult =
        await this.realmApp.currentUser.functions.exportReservations({
          event: this.event._id,
          language: this.$i18n.locale,
          timeZone: JSON.stringify(timeZone),
        });

      var exportedReservations = encodeURI(
        "data:text/csv;charset=utf-8," + exportReservationsResult
      );
      var link = document.createElement("a");
      link.setAttribute("href", exportedReservations);
      link.setAttribute("download", "reservations.csv");
      document.body.appendChild(link);
      link.click();

      this.exportInProgress = false;
    },
  },
  apollo: {
    event: {
      query: EventByIDQuery,
      variables() {
        return {
          query: {
            key: this.$route.params.eventKey,
          },
        };
      },
      skip() {
        return !this.$route.params.eventKey;
      },
      error(error) {
        this.eventByIDQueryError = error;
      },
    },
    timeSlots: {
      query: TimeSlotsQuery,
      variables() {
        return {
          query: {
            event: {
              key: this.$route.params.eventKey
                ? this.$route.params.eventKey
                : this.event.key,
            },
          },
        };
      },
      skip() {
        return !this.$route.params.eventKey && !this.event;
      },
      error(error) {
        this.timeSlotsQueryError = error;
      },
    },
    reservation: {
      query: ReservationByIDQuery,
      variables() {
        return {
          input: this.$route.params.editCode,
        };
      },
      skip() {
        return !this.$route.params.editCode;
      },
      update(data) {
        return data.findReservation;
      },
      result() {
        if (this.reservation) {
          this.event = this.reservation.timeSlot.event;

          this.$store.commit("setReservationToEdit", this.reservation);
          this.selectedDate = new Date(this.reservation.timeSlot.date);
          this.$store.commit(
            "setSelectedTimeSlotID",
            this.reservation.timeSlot._id
          );
        }
      },
      error(error) {
        this.reservationByIDQueryError = error;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.event-view-name {
  margin-top: @size32 !important;
}
.section-booking {
  background-color: @go-color-n10;
}
.qr-code {
  margin-top: @size24;
  background-color: lighten(@color-primary, 45%);
  padding: @size24;
  border-radius: @borderRadius;
  display: flex;
}
.qr-code-column {
  flex: 50%;

  &:first-of-type {
    margin-right: @size24;
  }
}
.qr-code-column-image {
  width: 100%;
  max-height: @size256;
  max-width: @size256;
  padding-right: @size24;
}
.booking-calendar {
  margin-top: @size32;
}
.event-view-actions {
  display: grid;
  grid-template-areas: "event-view-title event-view-action";
}
.event-view-title {
  margin-bottom: @size0 !important;
  grid-area: event-view-title;
}
.event-view-action {
  grid-area: event-view-action;
  text-align: right;
}
</style>
