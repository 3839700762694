<template>
  <Grid>
    <GridColumn :columns="8">
      <h1>{{ $t("loginModal.title") }}</h1>
      <Form @submit="validateForm()">
        <FormSection>
          <TextField
            id="key"
            v-model="key"
            autofocus
            :placeholder="$t('loginModal.textfieldPlaceholderKey')"
            :label="$t('loginModal.textfieldLabelKey')"
            type="password"
          />
        </FormSection>
        <FormSection v-if="validationErrors.length || loginError">
          <div v-if="validationErrors.length">
            <b v-t="'shared.validationErrorDescription'" />
            <ul>
              <li
                v-for="validationError in validationErrors"
                :key="validationError"
              >
                <p>{{ validationError }}</p>
              </li>
            </ul>
          </div>
          <p v-if="loginError" class="error">
            {{ loginError.message }}
          </p>
        </FormSection>
        <FormFooter>
          <ButtonGroup>
            <Button type="submit" appearance="primary">
              {{ $t("loginModal.buttonLogin") }}
            </Button>
            <Button appearance="subtle" @click="loginModalVisible = false">
              {{ $t("shared.buttonCancel") }}
            </Button>
          </ButtonGroup>
        </FormFooter>
      </Form>
    </GridColumn>
  </Grid>
</template>

<script>
import { login } from "@/graphql";
import { Grid, GridColumn } from "@gospore/gospore-web-ui-library-layout";
import Form, {
  FormSection,
  FormFooter,
} from "@gospore/gospore-web-ui-library-form";
import TextField from "@gospore/gospore-web-ui-library-textfield";
import Button, { ButtonGroup } from "@gospore/gospore-web-ui-library-button";

export default {
  name: "LoginModal",
  components: {
    Grid,
    GridColumn,
    Form,
    FormSection,
    FormFooter,
    TextField,
    Button,
    ButtonGroup,
  },
  model: {
    prop: "visible",
    event: "visible-changed",
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      key: null,
      validationErrors: [],
      loginError: null,
    };
  },
  methods: {
    async validateForm() {
      this.validationErrors = [];
      this.loginError = null;

      if (!this.key || this.key === "") {
        const missingNeccessaryFieldError = this.$t(
          "shared.validationErrorMissingNeccessaryField"
        );
        if (!this.validationErrors.includes(missingNeccessaryFieldError)) {
          this.validationErrors.push(missingNeccessaryFieldError);
        }
      }

      if (!this.validationErrors.length) {
        try {
          await login(this.key);
          console.log(this.$apollo.client);
          this.$router.push({ name: "EventListView" });
        } catch (error) {
          this.loginError = error;
        }
      }
    },
  },
};
</script>
