import Vue from "vue";
import Vuex from "vuex";
import i18n from "@/i18n";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    realmApp: null,
    user: null,
    event: null,
    selectedLocation: null,
    selectedTimeSlotID: null,
    reservationToEdit: null,
    reservationCurrentlyAccompanyingParticipants: null,
  },
  getters: {
    localizedText: () => (text) => {
      try {
        let translations = JSON.parse(text);
        let localizedText = translations.find(
          (translation) => translation.locale === i18n.locale
        );
        if (localizedText) {
          return localizedText.text;
        } else {
          let fallbackLocalizedText = translations.find(
            (translation) => translation.locale === i18n.fallbackLocale
          );
          return fallbackLocalizedText.text;
        }
      } catch {
        return "";
      }
    },
  },
  mutations: {
    setRealmApp(state, realmApp) {
      state.realmApp = realmApp;
    },
    setUser(state, user) {
      state.user = user;
    },
    setEvent(state, event) {
      state.event = event;
    },
    setSelectedLocation(state, location) {
      state.selectedLocation = location;
    },
    setSelectedTimeSlotID(state, timeSlotID) {
      state.selectedTimeSlotID = timeSlotID;
    },
    setReservationToEdit(state, reservation) {
      state.reservationToEdit = reservation;
    },
    setReservationCurrentlyAccompanyingParticipants(
      state,
      currentlyAccompanyingParticipants
    ) {
      state.reservationCurrentlyAccompanyingParticipants =
        currentlyAccompanyingParticipants;
    },
  },
  actions: {},
  modules: {},
});
