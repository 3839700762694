<template>
  <FormSection :title="title" :description="description">
    <div
      v-for="(translation, index) in translations"
      :key="translation.locale"
      class="translation-form-section"
    >
      <Select
        v-slot="{ query, inputFocused }"
        class="translation-form-section-select"
        :placeholder="$t('translationFormSection.selectPlaceholderTitle')"
        :label="$t('translationFormSection.selectLabelTitle')"
        :disabled="index === 0"
        :text="translation.locale"
      >
        <DropdownItemGroup :filter="query" :input-focused="inputFocused">
          <DropdownItem
            v-for="(locale, localeIndex) in availableLocales"
            :key="`locale-${localeIndex}`"
            :value="locale"
            :selected="translation.locale === locale"
            @click="translation.locale = locale"
          >
            {{ locale }}
          </DropdownItem>
        </DropdownItemGroup>
      </Select>
      <Textfield
        :id="`translationText-${label}-${index}`"
        v-model="translation.text"
        class="translation-form-section-textfield"
        :placeholder="placeholder"
        :label="label"
      />
      <Button
        v-if="index !== 0"
        class="translation-form-section-button"
        @click="translations.splice(index, 1)"
      >
        {{ $t("shared.dropdownItemDelete") }}
      </Button>
    </div>
    <Button
      v-if="translations.length < $i18n.availableLocales.length"
      class="translation-button-add"
      @click="addTranslation()"
    >
      {{ $t("translationFormSection.buttonAddTranslation") }}
    </Button>
  </FormSection>
</template>

<script>
const differenceBy = require("lodash.differenceby");
import { FormSection } from "@gospore/gospore-web-ui-library-form";
import Select from "@gospore/gospore-web-ui-library-select";
import {
  DropdownItemGroup,
  DropdownItem,
} from "@gospore/gospore-web-ui-library-dropdown-menu";
import Textfield from "@gospore/gospore-web-ui-library-textfield";
import Button from "@gospore/gospore-web-ui-library-button";

export default {
  name: "TranslationFormSection",
  components: {
    FormSection,
    Select,
    DropdownItemGroup,
    DropdownItem,
    Textfield,
    Button,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      translations: this.value
        ? this.value
        : [{ locale: this.$i18n.fallbackLocale, text: "" }],
    };
  },
  computed: {
    availableLocales() {
      let locales = this.translations.map((translation) => translation.locale);
      return differenceBy(this.$i18n.availableLocales, locales);
    },
  },
  watch: {
    value() {
      this.translations = this.value;
    },
    translations: {
      handler: function () {
        this.$emit("change", this.translations);
      },
      deep: true,
    },
  },
  methods: {
    addTranslation() {
      this.translations.push({ locale: "", text: "" });
    },
  },
};
</script>

<style lang="less">
.translation-form-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.translation-form-section:not(:first-of-type) {
  margin-top: @size16;
}
.translation-form-section-select {
  flex: 1;
  margin-top: 0;
}
.translation-form-section-textfield {
  flex: 3;
  margin-top: 0 !important;
  margin-left: @size8 !important;
  height: 100%;

  & > input {
    height: 29px;
  }
}
.translation-form-section-button {
  margin-left: @size8 !important;
}
.translation-button-add {
  margin-top: @size24 !important;
}
</style>
