var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"allow-outside-click":true,"close-on-overlay-click":!_vm.locationCreationInProgress},model:{value:(_vm.createLocationModalVisible),callback:function ($$v) {_vm.createLocationModalVisible=$$v},expression:"createLocationModalVisible"}},[_c('Form',{on:{"submit":function($event){return _vm.validateForm()}}},[_c('FormHeader',{attrs:{"title":!_vm.editMode
          ? _vm.$t('createLocationModal.titleCreate')
          : _vm.$t('createLocationModal.titleEdit')}}),_c('TranslationFormSection',{attrs:{"id":"name","description":!_vm.editMode
          ? _vm.$t('createLocationModal.descriptionCreate')
          : _vm.$t('createLocationModal.descriptionEdit'),"placeholder":_vm.$t('createLocationModal.textfieldPlaceholderName'),"label":_vm.$t('createLocationModal.textfieldLabelName')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('Textfield',{attrs:{"id":"address","placeholder":_vm.$t('createLocationModal.textfieldPlaceholderAddress'),"label":_vm.$t('createLocationModal.textfieldLabelAddress')},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('Textfield',{attrs:{"id":"postalCode","placeholder":_vm.$t('createLocationModal.textfieldPlaceholderPostalCode'),"label":_vm.$t('createLocationModal.textfieldLabelPostalCode')},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}}),(
        _vm.validationErrors.length ||
        _vm.createLocationMutationError ||
        _vm.updateLocationMutationError
      )?_c('FormSection',[(_vm.validationErrors.length)?_c('div',[_c('b',{directives:[{name:"t",rawName:"v-t",value:('shared.validationErrorDescription'),expression:"'shared.validationErrorDescription'"}]}),_c('ul',_vm._l((_vm.validationErrors),function(validationError){return _c('li',{key:validationError},[_c('p',[_vm._v(_vm._s(validationError))])])}),0)]):_vm._e(),(_vm.createLocationMutationError)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.createLocationMutationError.message)+" ")]):_vm._e(),(_vm.updateLocationMutationError)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.updateLocationMutationError.message)+" ")]):_vm._e()]):_vm._e(),_c('FormFooter',[_c('ButtonGroup',[_c('Button',{attrs:{"disabled":_vm.locationCreationInProgress,"type":"submit","appearance":"primary"}},[_vm._v(" "+_vm._s(!_vm.editMode ? _vm.$t("shared.buttonCreate") : _vm.$t("shared.buttonSave"))+" ")]),_c('Button',{attrs:{"disabled":_vm.locationCreationInProgress,"appearance":"subtle"},on:{"click":function($event){_vm.createLocationModalVisible = false}}},[_vm._v(" "+_vm._s(_vm.$t("shared.buttonCancel"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }