<template>
  <router-link
    :to="{
      name: 'EventView',
      params: { eventKey: event.key },
    }"
    class="event-row"
  >
    <h5>{{ localizedText(event.name) }}</h5>
    <div v-if="user && user.role === 'admin'">
      <div class="event-row-actions">
        <DropdownMenu>
          <template #trigger="{ triggerID, toggleOpen, selected }">
            <Button
              :id="triggerID"
              :selected="selected"
              class="button-actions"
              @click.prevent="toggleOpen"
            >
              ...
            </Button>
          </template>
          <DropdownItemGroup :title="$t('shared.dropdownGroupAdministration')">
            <DropdownItem @click="createEventModalVisible = true">
              {{ $t("shared.dropdownItemEdit") }}
            </DropdownItem>
            <DropdownItem @click="deleteEventModalVisible = true">
              {{ $t("shared.dropdownItemDelete") }}
            </DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      </div>
      <CreateEventModal
        v-model="createEventModalVisible"
        :edit-mode="true"
        :event="event"
      />
    </div>
    <Modal
      v-model="deleteEventModalVisible"
      :close-on-overlay-click="!eventDeletionInProgress"
    >
      <template #header>
        <ModalHeader :title="$t('eventView.deleteEventModalTitle')" />
      </template>
      <p>{{ $t("eventView.deleteEventModalDescription") }}</p>
      <p v-if="deleteEventMutationError" class="error">
        {{ deleteEventMutationError.message }}
      </p>
      <template #footer>
        <ModalFooter>
          <ButtonGroup>
            <Button
              :disabled="eventDeletionInProgress"
              appearance="danger"
              @click="deleteEvent()"
            >
              {{ $t("shared.dropdownItemDelete") }}
            </Button>
            <Button
              :disabled="eventDeletionInProgress"
              appearance="subtle"
              @click="deleteEventModalVisible = false"
            >
              {{ $t("shared.buttonCancel") }}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </template>
    </Modal>
  </router-link>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import Button, { ButtonGroup } from "@gospore/gospore-web-ui-library-button";
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from "@gospore/gospore-web-ui-library-dropdown-menu";
import Modal, {
  ModalHeader,
  ModalFooter,
} from "@gospore/gospore-web-ui-library-modal";
import CreateEventModal from "@/views/CreateEventModal.vue";

import DeleteEventMutation from "@/graphql/DeleteEventMutation.gql";

export default {
  name: "EventRow",
  components: {
    Button,
    ButtonGroup,
    DropdownMenu,
    DropdownItemGroup,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalFooter,
    CreateEventModal,
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      createEventModalVisible: false,
      deleteEventModalVisible: false,
      deleteEventMutationError: null,
      eventDeletionInProgress: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["localizedText"]),
  },
  methods: {
    deleteEvent() {
      this.eventDeletionInProgress = true;

      this.$apollo
        .mutate({
          mutation: DeleteEventMutation,
          variables: {
            input: this.event._id,
          },
          update: (store) => {
            const normalizedId = store.identify({
              _id: this.event._id,
              __typename: "Event",
            });
            store.evict({ id: normalizedId });
            store.gc();
          },
        })
        .then(() => {
          this.deleteEventModalVisible = false;
          this.eventDeletionInProgress = false;
        })
        .catch((error) => {
          this.deleteEventMutationError = error;
          this.eventDeletionInProgress = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.event-row {
  background-color: @go-color-n20;
  border-radius: @borderRadiusLarge;
  padding: @size24;
  transition: all 0.1s ease-out;
  cursor: pointer;
  text-decoration: initial;
  box-shadow: #elevation.card[light];
}
.event-row:hover {
  background-color: @go-color-n30;
}
.event-row-actions {
  margin-top: @size16;
}
.button-actions {
  background-color: @go-color-n10 !important;

  &:hover {
    background-color: @go-color-n20 !important;
  }
  &:active {
    background-color: fade(@go-color-b75, 60%) !important;
  }
  &.button-appearance-selected {
    background-color: darken(@color-secondary-shaded-blue, 10%) !important;

    &:hover {
      background-color: darken(@color-secondary-shaded-blue, 15%) !important;
    }
    &:active {
      background-color: lighten(@color-secondary-shaded-blue, 15%) !important;
    }
  }
}
</style>
