<template>
  <Modal
    v-model="createLocationModalVisible"
    :allow-outside-click="true"
    :close-on-overlay-click="!locationCreationInProgress"
  >
    <Form @submit="validateForm()">
      <FormHeader
        :title="
          !editMode
            ? $t('createLocationModal.titleCreate')
            : $t('createLocationModal.titleEdit')
        "
      />
      <TranslationFormSection
        id="name"
        v-model="name"
        :description="
          !editMode
            ? $t('createLocationModal.descriptionCreate')
            : $t('createLocationModal.descriptionEdit')
        "
        :placeholder="$t('createLocationModal.textfieldPlaceholderName')"
        :label="$t('createLocationModal.textfieldLabelName')"
      />
      <Textfield
        id="address"
        v-model="address"
        :placeholder="$t('createLocationModal.textfieldPlaceholderAddress')"
        :label="$t('createLocationModal.textfieldLabelAddress')"
      />
      <Textfield
        id="postalCode"
        v-model="postalCode"
        :placeholder="$t('createLocationModal.textfieldPlaceholderPostalCode')"
        :label="$t('createLocationModal.textfieldLabelPostalCode')"
      />
      <FormSection
        v-if="
          validationErrors.length ||
          createLocationMutationError ||
          updateLocationMutationError
        "
      >
        <div v-if="validationErrors.length">
          <b v-t="'shared.validationErrorDescription'" />
          <ul>
            <li
              v-for="validationError in validationErrors"
              :key="validationError"
            >
              <p>{{ validationError }}</p>
            </li>
          </ul>
        </div>
        <p v-if="createLocationMutationError" class="error">
          {{ createLocationMutationError.message }}
        </p>
        <p v-if="updateLocationMutationError" class="error">
          {{ updateLocationMutationError.message }}
        </p>
      </FormSection>
      <FormFooter>
        <ButtonGroup>
          <Button
            :disabled="locationCreationInProgress"
            type="submit"
            appearance="primary"
          >
            {{
              !editMode ? $t("shared.buttonCreate") : $t("shared.buttonSave")
            }}
          </Button>
          <Button
            :disabled="locationCreationInProgress"
            appearance="subtle"
            @click="createLocationModalVisible = false"
          >
            {{ $t("shared.buttonCancel") }}
          </Button>
        </ButtonGroup>
      </FormFooter>
    </Form>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
const cloneDeep = require("lodash.clonedeep");
import Modal from "@gospore/gospore-web-ui-library-modal";
import Form, {
  FormHeader,
  FormSection,
  FormFooter,
} from "@gospore/gospore-web-ui-library-form";
import Textfield from "@gospore/gospore-web-ui-library-textfield";
import Button, { ButtonGroup } from "@gospore/gospore-web-ui-library-button";
import TranslationFormSection from "@/components/TranslationFormSection.vue";

import CreateLocationMutation from "@/graphql/CreateLocationMutation.gql";
import LocationsQuery from "@/graphql/LocationsQuery.gql";
import UpdateLocationMutation from "@/graphql/UpdateLocationMutation.gql";

export default {
  name: "CreateLocationModal",
  components: {
    Modal,
    Form,
    FormHeader,
    FormSection,
    FormFooter,
    Textfield,
    Button,
    ButtonGroup,
    TranslationFormSection,
  },
  model: {
    prop: "visible",
    event: "visible-changed",
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      name: this.location ? JSON.parse(this.location.name) : null,
      address: this.location ? this.location.address : null,
      postalCode: this.location ? this.location.postalCode : null,
      createLocationModalVisible: this.visible,
      validationErrors: [],
      createLocationMutationError: null,
      updateLocationMutationError: null,
      locationCreationInProgress: false,
    };
  },
  computed: {
    ...mapState(["event"]),
  },
  watch: {
    createLocationModalVisible() {
      this.$emit("visible-changed", this.createLocationModalVisible);
    },
    visible() {
      this.createLocationModalVisible = this.visible;
    },
  },
  methods: {
    validateForm() {
      this.validationErrors = [];
      this.createLocationMutationError = null;
      this.updateLocationMutationError = null;

      if (
        !this.name ||
        !this.address ||
        !this.postalCode ||
        this.address === "" ||
        this.postalCode === ""
      ) {
        const missingNeccessaryFieldError = this.$t(
          "shared.validationErrorMissingNeccessaryField"
        );
        if (!this.validationErrors.includes(missingNeccessaryFieldError)) {
          this.validationErrors.push(missingNeccessaryFieldError);
        }
      } else {
        for (const translation of this.name) {
          if (
            !translation.locale ||
            !translation.text ||
            translation.locale === "" ||
            translation.text === "" ||
            (translation.locale === "" && translation.text === "")
          ) {
            const missingNeccessaryFieldError = this.$t(
              "shared.validationErrorMissingNeccessaryField"
            );
            if (!this.validationErrors.includes(missingNeccessaryFieldError)) {
              this.validationErrors.push(missingNeccessaryFieldError);
            }
          }
        }
      }

      if (!this.validationErrors.length) {
        if (this.editMode) {
          this.updateLocation();
        } else {
          this.createLocation();
        }
      }
    },
    createLocation() {
      this.locationCreationInProgress = true;

      this.$apollo
        .mutate({
          mutation: CreateLocationMutation,
          variables: {
            data: {
              name: JSON.stringify(this.name),
              address: this.address,
              postalCode: this.postalCode,
              event: { link: this.event._id },
            },
          },
          update: (store, { data: { insertOneLocation } }) => {
            const locationsData = store.readQuery({
              query: LocationsQuery,
              variables: {
                query: {
                  event: { key: this.$route.params.eventKey },
                },
              },
            });
            const locationsDataClone = cloneDeep(locationsData);
            locationsDataClone.locations.push(insertOneLocation);
            store.writeQuery({
              query: LocationsQuery,
              variables: {
                query: {
                  event: { key: this.$route.params.eventKey },
                },
              },
              data: locationsDataClone,
            });
          },
        })
        .then(() => {
          this.createLocationModalVisible = false;
          this.locationCreationInProgress = false;

          this.name = null;
          this.address = null;
          this.postalCode = null;
          this.validationErrors = [];
          this.createLocationMutationError = null;
          this.updateLocationMutationError = null;
        })
        .catch((error) => {
          this.createLocationMutationError = error;
          this.locationCreationInProgress = false;
        });
    },
    updateLocation() {
      this.locationCreationInProgress = true;

      this.$apollo
        .mutate({
          mutation: UpdateLocationMutation,
          variables: {
            set: {
              name: JSON.stringify(this.name),
              address: this.address,
              postalCode: this.postalCode,
            },
            query: { _id: this.location._id },
          },
        })
        .then(() => {
          this.createLocationModalVisible = false;
          this.locationCreationInProgress = false;

          this.validationErrors = [];
          this.createLocationMutationError = null;
          this.updateLocationMutationError = null;
        })
        .catch((error) => {
          this.updateLocationMutationError = error;
          this.locationCreationInProgress = false;
        });
    },
  },
};
</script>
