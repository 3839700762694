<template>
  <div
    class="location-row"
    :class="{
      selected: selectedLocation && location._id === selectedLocation._id,
    }"
    @click="selectLocation()"
  >
    <h5>{{ localizedText(location.name) }}</h5>
    <p>
      {{ location.address }}, {{ location.postalCode }}
      {{ localizedText(location.name) }}
    </p>
    <p class="location-row-detail">{{ duration }}</p>
    <div v-if="user && user.role === 'admin' && $route.params.eventKey">
      <div class="location-row-actions">
        <DropdownMenu>
          <template #trigger="{ triggerID, toggleOpen, selected }">
            <Button
              :id="triggerID"
              class="button-actions"
              :selected="selected"
              @click.stop="toggleOpen"
            >
              ...
            </Button>
          </template>
          <DropdownItemGroup :title="$t('shared.dropdownGroupAdministration')">
            <DropdownItem @click="createLocationModalVisible = true">
              {{ $t("shared.dropdownItemEdit") }}
            </DropdownItem>
            <DropdownItem @click="deleteLocationModalVisible = true">
              {{ $t("shared.dropdownItemDelete") }}
            </DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      </div>
      <CreateLocationModal
        v-model="createLocationModalVisible"
        :edit-mode="true"
        :location="location"
      />
    </div>
    <Modal
      v-model="deleteLocationModalVisible"
      :close-on-overlay-click="!locationDeletionInProgress"
    >
      <template #header>
        <ModalHeader :title="$t('locationList.deleteLocationModalTitle')" />
      </template>
      <p>{{ $t("locationList.deleteLocationModalDescription") }}</p>
      <p v-if="deleteLocationMutationError" class="error">
        {{ deleteLocationMutationError.message }}
      </p>
      <template #footer>
        <ModalFooter>
          <ButtonGroup>
            <Button
              :disabled="locationDeletionInProgress"
              appearance="danger"
              @click="deleteLocation()"
            >
              {{ $t("shared.dropdownItemDelete") }}
            </Button>
            <Button
              :disabled="locationDeletionInProgress"
              appearance="subtle"
              @click="deleteLocationModalVisible = false"
            >
              {{ $t("shared.buttonCancel") }}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
const cloneDeep = require("lodash.clonedeep");
import Button, { ButtonGroup } from "@gospore/gospore-web-ui-library-button";
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from "@gospore/gospore-web-ui-library-dropdown-menu";
import Modal, {
  ModalHeader,
  ModalFooter,
} from "@gospore/gospore-web-ui-library-modal";
import CreateLocationModal from "@/views/CreateLocationModal.vue";

import DeleteLocationMutation from "@/graphql/DeleteLocationMutation.gql";
import LocationsQuery from "@/graphql/LocationsQuery.gql";

export default {
  name: "LocationRow",
  components: {
    Button,
    ButtonGroup,
    DropdownMenu,
    DropdownItemGroup,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalFooter,
    CreateLocationModal,
  },
  props: {
    location: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      createLocationModalVisible: false,
      deleteLocationModalVisible: false,
      deleteLocationMutationError: null,
      locationDeletionInProgress: false,
    };
  },
  computed: {
    duration() {
      let startDate = this.location.startDate
        ? new Date(this.location.startDate)
        : null;
      let endDate = this.location.endDate
        ? new Date(this.location.endDate)
        : null;
      if (startDate && endDate && startDate.getTime() === endDate.getTime()) {
        return startDate.toLocaleDateString(this.$i18n.locale, {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      } else if (startDate && endDate) {
        return `${startDate.toLocaleDateString(this.$i18n.locale, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })} - ${endDate.toLocaleDateString(this.$i18n.locale, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}`;
      } else {
        return "";
      }
    },
    ...mapState(["selectedLocation", "user"]),
    ...mapGetters(["localizedText"]),
  },
  methods: {
    selectLocation() {
      if (
        this.selectedLocation &&
        this.selectedLocation._id === this.location._id
      ) {
        this.$store.commit("setSelectedLocation", null);
      } else {
        this.$store.commit("setSelectedLocation", this.location);

        // this.$nextTick(() => {
        //   const element = document.getElementById("bookingCalendar");
        //   const yOffset = -32;
        //   const y =
        //     element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        //   window.scrollTo({ top: y, behavior: "smooth" });
        // });
      }
    },
    deleteLocation() {
      this.locationDeletionInProgress = true;

      this.$apollo
        .mutate({
          mutation: DeleteLocationMutation,
          variables: {
            input: this.location._id,
          },
          update: (store) => {
            const locationsData = store.readQuery({
              query: LocationsQuery,
              variables: {
                query: {
                  event: { key: this.$route.params.eventKey },
                },
              },
            });
            const locationsDataClone = cloneDeep(locationsData);
            const index = locationsDataClone.locations.findIndex(
              (location) => location._id === this.location._id
            );
            locationsDataClone.locations.splice(index, 1);
            store.writeQuery({
              query: LocationsQuery,
              variables: {
                query: {
                  event: { key: this.$route.params.eventKey },
                },
              },
              data: locationsDataClone,
            });
          },
        })
        .then(() => {
          this.deleteLocationModalVisible = false;
          this.locationDeletionInProgress = false;
        })
        .catch((error) => {
          this.deleteLocationMutationError = error;
          this.locationDeletionInProgress = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.location-row {
  background-color: @go-color-n20;
  border-radius: @borderRadiusLarge;
  padding: @size24;
  transition: all 0.1s ease-out;
  cursor: pointer;
  text-decoration: initial;
  border: @borderWidth solid transparent;
  box-shadow: #elevation.card[light];
}
.location-row:hover {
  background-color: @go-color-n30;
}
.location-row-detail {
  margin-top: @size4;
}
.location-row-actions {
  margin-top: @size16;
}
.selected {
  background-color: fade(@color-primary, 100%);
  color: @go-color-n0;
}
.selected:hover {
  background-color: fade(@color-primary, 90%);
  color: @go-color-n0;
}
.location-row.selected h5 {
  color: @go-color-n0 !important;
}
.button-actions {
  background-color: @go-color-n10 !important;

  &:hover {
    background-color: @go-color-n20 !important;
  }
  &:active {
    background-color: fade(@go-color-b75, 60%) !important;
  }
  &.button-appearance-selected {
    background-color: darken(@color-secondary-shaded-blue, 10%) !important;

    &:hover {
      background-color: darken(@color-secondary-shaded-blue, 15%) !important;
    }
    &:active {
      background-color: lighten(@color-secondary-shaded-blue, 15%) !important;
    }
  }
}
</style>
