<template>
  <FormSection
    :description="
      !editMode ? $t('reservationParticipantFormSection.description') : ''
    "
  >
    <Select
      v-slot="{ query, inputFocused }"
      :placeholder="
        $t('reservationParticipantFormSection.selectPlaceholderTitle')
      "
      :label="$t('reservationParticipantFormSection.selectLabelTitle')"
      :text="titleDescription"
      :disabled="editMode"
      class="select-salutation"
    >
      <DropdownItemGroup :filter="query" :input-focused="inputFocused">
        <DropdownItem
          key="_0"
          :value="$t('reservationParticipantFormSection.titles[0]')"
          :selected="internalParticipant.title === '_0'"
          @click="internalParticipant.title = '_0'"
        >
          {{ $t("reservationParticipantFormSection.titles[0]") }}
        </DropdownItem>
        <DropdownItem
          key="_1"
          :value="$t('reservationParticipantFormSection.titles[1]')"
          :selected="internalParticipant.title === '_1'"
          @click="internalParticipant.title = '_1'"
        >
          {{ $t("reservationParticipantFormSection.titles[1]") }}
        </DropdownItem>
      </DropdownItemGroup>
    </Select>
    <Textfield
      :id="`firstname${index}`"
      v-model="internalParticipant.firstname"
      :placeholder="
        $t('reservationParticipantFormSection.textfieldPlaceholderFirstname')
      "
      :label="$t('reservationParticipantFormSection.textfieldLabelFirstname')"
      :disabled="editMode"
    />
    <Textfield
      :id="`lastname${index}`"
      v-model="internalParticipant.lastname"
      :placeholder="
        $t('reservationParticipantFormSection.textfieldPlaceholderLastname')
      "
      :label="$t('reservationParticipantFormSection.textfieldLabelLastname')"
      :disabled="editMode"
    />
    <Textfield
      :id="`emailAddress${index}`"
      v-model="internalParticipant.emailAddress"
      :placeholder="
        $t('reservationParticipantFormSection.textfieldPlaceholderEmailAddress')
      "
      :label="
        $t('reservationParticipantFormSection.textfieldLabelEmailAddress')
      "
      type="email"
      :disabled="editMode"
    />
    <Textfield
      :id="`company${index}`"
      v-model="internalParticipant.company"
      :placeholder="
        $t('reservationParticipantFormSection.textfieldPlaceholderCompany')
      "
      :label="$t('reservationParticipantFormSection.textfieldLabelCompany')"
      :disabled="editMode"
    />
    <Textfield
      :id="`phoneNumber${index}`"
      v-model="internalParticipant.phoneNumber"
      :placeholder="
        $t('reservationParticipantFormSection.textfieldPlaceholderPhoneNumber')
      "
      :label="$t('reservationParticipantFormSection.textfieldLabelPhoneNumber')"
      type="tel"
      :disabled="editMode"
    />
    <Textfield
      :id="`addressPostalCode${index}`"
      v-model="internalParticipant.addressPostalCode"
      :placeholder="
        $t(
          'reservationParticipantFormSection.textfieldPlaceholderAddressPostalCode'
        )
      "
      :label="
        $t('reservationParticipantFormSection.textfieldLabelAddressPostalCode')
      "
      :disabled="editMode"
    />
    <Textfield
      :id="`addressCity${index}`"
      v-model="internalParticipant.addressCity"
      :placeholder="
        $t('reservationParticipantFormSection.textfieldPlaceholderAddressCity')
      "
      :label="$t('reservationParticipantFormSection.textfieldLabelAddressCity')"
      :disabled="editMode"
    />
    <Textfield
      :id="`addressCountry${index}`"
      v-model="internalParticipant.addressCountry"
      :placeholder="
        $t(
          'reservationParticipantFormSection.textfieldPlaceholderAddressCountry'
        )
      "
      :label="
        $t('reservationParticipantFormSection.textfieldLabelAddressCountry')
      "
      :disabled="editMode"
    />
    <Button
      v-if="index !== null"
      class="button-remove"
      @click="removeParticipant()"
    >
      {{
        $t(
          "reservationParticipantFormSection.buttonRemoveAccompanyingParticipant",
          {
            firstname: internalParticipant.firstname,
            lastname: internalParticipant.lastname,
          }
        )
      }}
    </Button>
  </FormSection>
</template>

<script>
import { FormSection } from "@gospore/gospore-web-ui-library-form";
import Select from "@gospore/gospore-web-ui-library-select";
import {
  DropdownItemGroup,
  DropdownItem,
} from "@gospore/gospore-web-ui-library-dropdown-menu";
import Textfield from "@gospore/gospore-web-ui-library-textfield";
import Button from "@gospore/gospore-web-ui-library-button";

export default {
  name: "ReservationParticipantFormSection",
  components: {
    FormSection,
    Select,
    DropdownItemGroup,
    DropdownItem,
    Textfield,
    Button,
  },
  model: {
    prop: "participant",
    event: "change",
  },
  props: {
    participant: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalParticipant: this.participant,
    };
  },
  computed: {
    titleDescription() {
      switch (this.internalParticipant.title) {
        case "_0":
          return this.$t("reservationParticipantFormSection.titles[0]");
        case "_1":
          return this.$t("reservationParticipantFormSection.titles[1]");
        default:
          return "";
      }
    },
  },
  watch: {
    participant() {
      this.internalParticipant = this.participant;
    },
    internalParticipant() {
      this.$emit("change", this.internalParticipant);
    },
  },
  methods: {
    removeParticipant() {
      this.$emit("remove-participant", this.index);
    },
  },
};
</script>

<style lang="less">
.select-salutation .select-trigger {
  border: @borderWidth solid @go-color-n30 !important;
  background-color: @go-color-n30 !important;
}
.button-remove {
  margin-top: @size24 !important;
}
</style>
