<template>
  <div class="location-list">
    <!-- <h3>{{ $t("locationList.title") }}</h3> -->
    <div v-if="!loading">
      <div v-if="!locationsQueryError">
        <div v-if="sortedLocations.length" class="location-list-grid">
          <LocationRow
            v-for="location in sortedLocations"
            :key="location._id"
            :location="location"
          />
        </div>
        <p v-else>{{ $t("locationList.listEmpty") }}</p>
      </div>
      <p v-else class="error">
        {{ locationsQueryError.message }}
      </p>
      <div v-if="user && user.role === 'admin' && $route.params.eventKey">
        <div class="location-list-actions">
          <Button @click="createLocationModalVisible = true">
            {{ $t("locationList.buttonCreateLocation") }}
          </Button>
        </div>
        <CreateLocationModal v-model="createLocationModalVisible" />
      </div>
    </div>
    <p v-else>Loading..</p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Button from "@gospore/gospore-web-ui-library-button";
import LocationRow from "@/components/LocationRow.vue";
import CreateLocationModal from "@/views/CreateLocationModal.vue";

import LocationsQuery from "@/graphql/LocationsQuery.gql";

export default {
  name: "LocationList",
  components: {
    Button,
    LocationRow,
    CreateLocationModal,
  },
  data() {
    return {
      locations: [],
      locationsQueryError: null,
      createLocationModalVisible: false,
    };
  },
  computed: {
    sortedLocations() {
      const sortedLocations = [...this.locations].sort(function (a, b) {
        return new Date(b.startDate) - new Date(a.startDate);
      });
      return sortedLocations.reverse();
    },
    loading() {
      return this.$apollo.queries.locations.loading;
    },
    ...mapState(["event", "selectedLocation", "user"]),
  },
  watch: {
    locations() {
      if (this.selectedLocation) {
        this.$store.commit(
          "setSelectedLocation",
          this.locations.find(
            (location) => location._id === this.selectedLocation._id
          )
        );
      }
    },
  },
  apollo: {
    locations: {
      query: LocationsQuery,
      variables() {
        return {
          query: {
            event: {
              key: this.$route.params.eventKey
                ? this.$route.params.eventKey
                : this.event.key,
            },
          },
        };
      },
      skip() {
        return !this.$route.params.eventKey && !this.event;
      },
      error(error) {
        this.locationsQueryError = error;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.location-list {
  margin-top: @size32;
}
.location-list-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: @size16;
  margin-top: @size32;
}
@media only screen and (max-width: 960px) {
  .location-list-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 720px) {
  .location-list-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.location-list-actions {
  margin-top: @size24;
}
</style>
