<template>
  <DropdownMenu
    v-if="user"
    v-model="open"
    :text="user.role"
    :disabled="user.role !== 'admin' || user.role !== 'agent'"
    class="user-dropdown-menu"
  >
    <DropdownItemGroup>
      <DropdownItem @click="logout()">Logout</DropdownItem>
    </DropdownItemGroup>
  </DropdownMenu>
</template>

<script>
import { mapState } from "vuex";
import { logout } from "@/graphql";
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from "@gospore/gospore-web-ui-library-dropdown-menu";

export default {
  name: "UserDropdownMenu",
  components: {
    DropdownMenu,
    DropdownItemGroup,
    DropdownItem,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    logout() {
      logout();
    },
  },
};
</script>

<style lang="less" scoped>
.user-dropdown-menu {
  margin-right: @size8;
}
</style>
